import * as _ from 'lodash-es';

export function init() {
  if (!localStorage.getItem('settings')) {
    const initSettings = {
      interface: {
        gridCam: 3,
        showCamName: false,
        showTimer: true,
        hideSidebar: false,
        camOrder2: {},
      }
    };
    localStorage.setItem('settings', JSON.stringify(initSettings));
  }
  if (getSettingValue('interface.camOrder2') == null) {
    setSettingValue('interface.camOrder2', {});
  }
}

/**
 * Возвращает заданную настройку по пути
 */
export function getSettingValue(path) {
  return _.get(JSON.parse(localStorage.getItem('settings')), path, null);
}

/**
 * Задает значение настройке
 */
export function setSettingValue(path, value) {
  try {
    if (localStorage.getItem('settings')) {
      const result = _.set(JSON.parse(localStorage.getItem('settings')), path, value);

      localStorage.setItem('settings', JSON.stringify(result));
    }
  } catch (e) {
    init();

    console.dir(e);
    console.error('Ошибка при задании настройки');
  }
}
