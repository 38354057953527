export const generateKeys = (input, parentKey = '', startLevel = 0) => {
  const copy = [...input]
  const createKeys = (t, parentKey) => {
    let level = startLevel
    for (let node of t) {
      node.key = parentKey.length === 0 ? `${level}` : `${parentKey}-${level}`
      level++
      if (!isNodeLeaf(node)) {
        createKeys(node.children, node.key)
      }
    }
  }
  createKeys(copy, parentKey)
  return copy
}
export const isNodeLeaf = (node) => {
  return !node.children || node.children.length === 0
}

export const findByKey = (root, key, modifierFunction = null) => {
    // Base case: If the node is null, the key is not found
    if (!root) return null;
  
    // Check if the current node's key matches the search key
    if (root.key === key) {
      // Apply modifier function if provided
      if (modifierFunction) modifierFunction(root);
      return root;
    }
  
    // Recursively search the children
    for (const child of root.children || []) {
      const result = findByKey(child, key, modifierFunction);
      if (result) return result;
    }
  
    // Key not found in current node or children
    return null;
  }
  