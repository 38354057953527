<template>
  <div class="error-404">
    <div class="text-block">
      <span>404</span>
      <router-link to="/"><i class="mdi mdi-home mdi-18px"></i> На главную</router-link>
      <br>
    </div>
  </div>
</template>

<style lang="css">

.error-404 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #fff url("../assets/img/error-bg.jpg") center no-repeat;
}

.text-block {
  flex: auto;
  text-align: center;
  padding-top: 100px;

}

.text-block .img {
  width: 800px;
  /*opacity: 0.1;*/
  /*opacity: 0.1;*/
}

.text-block span {
  font-size: 308px;
  color: #c9c9c9;
  display: block;
  font-weight: bold;
  margin-bottom: 50px;
  line-height: 249px;
  /*text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);*/
}

.text-block a {
  font-size: 13px;
  color: #fff;
  background: rgba(62, 74, 125, 0.62);
  transition: all 300ms;
  padding: 5px 15px;
  text-transform: uppercase;
  border-radius: 3px;
}

.text-block a:hover {
  color: #9cc9c0;
  background: #fff;
  text-decoration: none;
}

</style>
