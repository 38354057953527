<template>
  <div class="list">
    <div class="list__nav">
      <h4 class="list__title">
        Список загружаемых файлов
      </h4>
      <button type="button" class="list__button" @click="hide">
        <i class="bx bx-x list__icon"></i>
      </button>
    </div>

    <p class="list__text" v-if="progress.length === 0">
      Файлы готовятся к скачиванию...
    </p>
    <template v-else>
      <div class="list__item" v-for="file in progress" :key="file.filename">
        <h5 class="list__title list__title--progress">
          {{ file.filename }}
        </h5>
        <span class="list__symbol">
          -
        </span>
        <p class="list__text" v-if="file.percent !== false">
          {{ file.percent }}%
        </p>
        <a class="list__link" v-if="file.ready" :href="file.fullPath" :download="file.filename" target="_blank">
          Скачать
        </a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    hide() {
      this.$emit('hidden');
    }
  }
};
</script>

<style>
.list {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #272c33;
  border: 2px solid #383e47;
  color: #fff;
  margin-bottom: 0px;
  padding-bottom: 5px;
}

.list__nav {
  border-bottom: 2px solid #383e47;
  padding: 5px;
  position: relative;
  margin-bottom: 5px;
}

.list__button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: none;
  padding: 0;
}

.list__button:focus {
  outline: none;
}

.list__icon {
  font-size: 20px;
  display: block;
  color: #fafafa;
}

.list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #383e47;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.list__item:last-child {
  border: none;
  margin-bottom: 0px;
}

.list__title {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.list__title--progress {
  /* нужно, чтобы при обрезании имени файла, было видно последние символы */
  /*direction: rtl; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list__symbol {
  margin: 0px 5px;
}

.list__text {
  color: #fff;
  margin-bottom: 0px;
  font-size: 14px;
}

.list__link {
  margin-left: 10px;
  padding: 1px 6px;
  background: #389af0;
  border: none;
  color: #fff;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

.list__link:hover,
.list__link:focus {
  background: #1e88e5;
  color: #fffa;
  text-decoration: none;
}

.list__link:disabled,
.list__link[disabled] {
  pointer-events: none;
  cursor: no-drop;
  background: #777;
  color: #fff8;
}
</style>
