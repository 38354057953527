<template>
  <div class="timer">
    {{ visibleTime }}
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Timer',
  components: {},
  mixins: [],
  props: {
    value: {
      type: Object,
      require: true
    }
  },

  data() {
    return {
      videoTime: '',
      timer: setInterval(this.runTimer, 1000),
      visibleTime: ''
    };
  },

  watch: {
    value(to) {
      // console.log('VALUE - ', to);
      this.videoTime = to;
      // this.stopTimer();
      this.runTimer();
    }
  },

  mounted() {
    moment.locale('ru');
    this.videoTime = JSON.parse(JSON.stringify(this.value));
    this.visibleTime = moment(this.videoTime).format('DD.MM.YYYY, HH:mm:ss');
  },

  methods: {
    runTimer() {
      this.videoTime = moment(this.videoTime).add(1, 'seconds');
      this.visibleTime = moment(this.videoTime).format('DD.MM.YYYY, HH:mm:ss');
      // console.log(this.videoTime);

    },

    stopTimer() {
      clearInterval(this.timer);
      this.runTimer();
    }

  }
};
</script>

<style lang="stylus" scoped>
  .timer {
    display: inline-block
  }
</style>
