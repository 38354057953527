<template>
    <div class="sidebar-tree" v-show="!hide">
        <TreeView :root="treeData" v-memo="[treeData]"
                  :lazy="{ 'group': fetchGroupsLazy }">
            <template #group="{ item, toggle, isOpen, isLoading }">
                <div v-if="item.type === 'group'" class="item" @click="toggle">
                    <span class="mdi group-icon mdi-folder"></span>
                    <span class="name group-name">{{ item.groupName }}</span>
                    <div class="loader" v-if="isLoading"></div>
                    <i v-else i class="mdi arrow mdi-chevron-down"
                       :style="{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }"></i>
                </div>
            </template>
            <template #division="{ item, toggle, isOpen }">
                <Division :item="item" :toggle="toggle" :isOpen="isOpen" @selectDivision="selectDivision" />
            </template>
            <template #cam="{ item }">
                <div v-if="item.type === 'cam'" class="item" @click="selectCam(item)"
                     :class="{ selected: $route.params.cam === item.name && $route.params.division === item.division }">
                    <span class="mdi cam-icon mdi-camera"></span>
                    <span class="name cam-name">{{ item.displayName }}</span>
                </div>
            </template>
        </TreeView>
    </div>
</template>


<script>
import TreeView from '../TreeView';
import Division from './TreeTemplates/Division.vue';
import { useDefaultStore } from '@/store';
import { ApiSys } from "@/services/api";
export default {
    name: 'SidebarTree',
    components: {
        TreeView, Division
    },
    props: {
        treeData: {
            type: Array,
            required: true
        },
        hide: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useDefaultStore();
        return {
            store
        };
    },
    mounted() {
        console.log(this.$refs)
    },
    methods: {
        selectDivision(division) {
            this.$router.push({ name: 'cam-listNew', params: { division: division.divisionId } });
            if (this.store.smallScreen) {
               this.store.setHideSidebar(true);
            }
        },
        selectCam(cam) {
            this.$router.push({ name: 'cam-new', params: { cam: cam.name, division: cam.division } });
            if (this.store.smallScreen) {
               this.store.setHideSidebar(true);
            }
        },
        async fetchGroupsLazy(item) {
            let result = await ApiSys.getGroupDivisions(item.groupId);
            const sorted = result.data.sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            })
            const groups = [];
            for (let division of sorted) {
                const child = {
                    type: "division",
                    divisionId: division.divisionGuid,
                    name: division.name,
                    children: division.cameras.map((c) => {
                        return {
                            id: Math.random(),
                            division: division.divisionGuid,
                            type: "cam",
                            name: c.name,
                            displayName: c.displayName,
                        };
                    }),
                }
                groups.push(child);
            }
            return groups;
        }
    }
}
</script>


<style scoped>
.sidebar-tree {
    user-select: none;
}
.item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
}

.item:hover {
    background-color: #464646;
}

.selected {
    background-color: #389af0;
}

.group-icon {
    font-size: 30px;
}

.group-name {
    font-size: 18px;
    margin-left: 10px;
    overflow: hidden;
}

.name-inner {
    transition: transform 0.2s linear;
}
.item:hover .name>.name-inner {
    transform: translateX(-50%);
}
.arrow {
    font-size: 30px;
    margin-left: auto;
    transition: transform 0.2s linear;
}

.division-icon {
    font-size: 30px;
}

.division-name {
    font-size: 18px;
    margin-left: 10px;
    overflow: hidden;
}

.cam-icon {
    font-size: 30px;
}

.cam-name {
    font-size: 18px;
    margin-left: 10px;
}


.loader {
    width: 30px;
    margin-left: auto;
    height: 30px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>