<template>
  <div class="fade modal-backdrop show" v-if="isOpen"></div>
  <VueDatePicker format="dd.MM.yyyy" locale="ru-RU" dark :loading="loading" teleport-center v-model="date" @open="opened" ref="datePicker"
                 @closed="closed" :allowed-dates="allowedDays" :enable-time-picker="false" auto-apply
                 @update-month-year="handleMonthYear" :clearable="false">
  </VueDatePicker>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "Calendar",
  data() {
    return {
      currentMonth: null,
      currentYear: null,
      isOpen: false,
      oldDate: null,
      date: new Date(),
      loading: false,
      allowedDays: []
    }
  },
  components: {
    VueDatePicker
  },
  emits: ['navigation', "input"],
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    calendar() {
      return this.$refs.datePicker ? this.$refs.datePicker : null;
    }
  },
  methods: {
    opened() {
      this.isOpen = true;
      this.$emit('navigation', { month: this.date.getMonth() + 1, year: this.date.getFullYear() });
    },
    closed() {
      this.isOpen = false;
      this.$emit('input', this.date);
    },
    handleMonthYear({ month, year }) {
      this.$emit('navigation', { month: month+1, year: year });
    },
    getState() {
      if (this.calendar && this.isOpen) {
        const el = document.querySelector('.theme-blue .vdatetime-calendar');

        if (el) {
          const month = el.__vue__.newDate.c.month;
          const year = el.__vue__.newDate.c.year;

          if (this.currentMonth !== month || this.currentYear !== year) {
            this.currentMonth = month;
            this.currentYear = year;

            this.$emit('navigation', { month, year });

            this.enableAllDays();
          }
        }
      } else {
        this.currentMonth = null;
        this.currentYear = null;
      }
    },

    setStyleForDay(day, cssText = '') {
      if (this.calendar && this.calendar.isOpen) {
        const el = document.querySelectorAll('.theme-blue .vdatetime-calendar .vdatetime-calendar__month__day');

        if (el && el.length) {
          el.forEach(e => {
            if (parseInt(e.innerText) === Number(day)) {
              e.style.cssText = cssText;
            }
          })
        }
      }
    },

    enableDay(day) {
      this.allowedDays.push(day);
    },

    enableAllDays() {
      for (let i = 1; i <= 31; i++) {
        this.setStyleForDay(i);
      }
    },

    setLoadState(state = true) {
      this.loading = state;
    }
  },
  mounted() {
    this.$nextTick(() => {
      //this.$emit('input', this.date);
    })
  },
  beforeUnmount() {
    //clearInterval(resource);
  }
}
</script>

<style lang="stylus">
  .vdatetime-popup__body {
    position: relative;
  }

  .vdatetime-loader {
    display flex;
    justify-content center
    align-items center
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align center;
    background-color: rgba(255, 255, 255, 0.6)
  }

  .theme-blue .vdatetime-popup__header,
  .theme-blue .vdatetime-calendar__month__day--selected > span > span,
  .theme-blue .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #389af0;
  }

  .theme-blue .vdatetime-year-picker__item--selected,
  .theme-blue .vdatetime-time-picker__item--selected,
  .theme-blue .vdatetime-popup__actions__button {
    color: #389af0;
  }

  @media (max-width: 768px) {
    .vdatetime-popup {
      position absolute !important
    }
  }
</style>
