<template>
    <div v-if="!loading" class="box">
        <div class="box-header">
            <CFormInput v-model="userFilter" placeholder="Поиск" autocomplete="off" />
        </div>
    </div>
    <div v-if="loading" class="row">
        <div class="loader"></div>
    </div>
    <div v-if="!loading" class="row">
        <div v-for="(user, idx) of paginatedFilteredList" :key="idx" class="col-sm-12 col-md-4 col-xl-4">
            <div class="box box-danger">
                <div class="box-header d-flex justify-content-between align-content-center">
                    <h3 class="box-title" style="font-size: 12px">{{ user.user }}</h3>
                </div>
                <div class="box-footer d-grid">
                    <button type="button" class="btn btn-block btn-default btn-sm" @click="putUserToGroup(user)">
                        Выбрать
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <Pagination v-if="!loading" :currentPage="page" :totalPages="Math.ceil(filteredSortedList.length / 12)"
                    @update:currentPage="(p) => { page = p }" :maxVisiblePages="5" />
    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { ApiSys } from '@/services/api';
import { CFormInput } from '@coreui/bootstrap-vue';
import { useDefaultStore } from '@/store';
export default {
    name: "AddUserToGroup",
    components: { CFormInput, Pagination },
    props: {
        group: Object,
        hide: Function
    },
    emits: ["updateTree"],
    data() {
        return {
            userFilter: '',
            allUsers: [],
            page: 1,
            loading: true
        }
    },
    watch: {
        userFilter() {
            this.page = 1;
        }
    },
    computed: {
        filteredSortedList() {
            return this.allUsers
                .filter(u => u.user.toLowerCase().includes(this.userFilter.toLowerCase()))
        },
        paginatedFilteredList() {
            return this.filteredSortedList
                .slice((this.page - 1) * 12, this.page * 12)
        }
    },
    methods: {
        async putUserToGroup(user) {
            let answer = confirm(`Пользователь будет добавлен во все площадки, входящие в группу "${this.group.name}", продолжить?`);
            if (answer) {
                const response = await ApiSys.addUserGroupAdmin(user.user, this.group.id);
                if (response.error) {
                    this.$toast.error(response.error.message);
                } else {
                    this.$emit('updateTree');
                    this.$toast.success('Пользователь успешно добавлен в группу!');
                }
                this.hide();
            }
        },
    },
    async created() {
        this.loading = true;
        this.allUsers = await ApiSys.getAdminUsers();
        this.loading = false;
    },
    setup() {
        const store = useDefaultStore();
        return {
            store
        };
    }
}
</script>

<style scoped>
.loader {
    width: 64px;
    margin: auto;
    height: 64px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>