<template>
    <div class="row">
        <div class="col-12 d-grid">
            <div class="btn btn-info btn-block mb-1" @click="createUserDialog">Создать нового пользователя</div>
        </div>
        <div class="col-12 d-grid">
            <div class="btn btn-info btn-block mb-1" @click="addExistingUser">Добавить существующего пользователя
            </div>
        </div>
    </div>
</template>

<script>
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/bootstrap-vue';
import { inject } from 'vue';
import UserEdit from './UserEdit.vue';
import AddUsersToDivision from './AddUsersToDivision.vue';
export default {
    name: 'AddUser',
    components: { CModal, CModalBody, CModalHeader, CModalTitle },
    emits: ["updateTree"],
    props: {
        division: {
            type: Object,
            default: () => { }
        },
        hide: Function
    },
    setup() {
        const { showModal, hideModal } = inject('modal');
        return { showModal, hideModal }
    },
    methods: {
        createUserDialog() {
            this.hide();
            this.showModal(UserEdit, { division: this.division, hide: this.hide, register: true }, { "updateTree": () => { this.$emit("updateTree") } }, "Создать нового пользователя", "xl");
        },
        addExistingUser() {
            this.hide();
            this.showModal(AddUsersToDivision, { division: this.division, hide: this.hide }, { "updateTree": () => { this.$emit("updateTree") } }, "Добавить существующего пользователя");
        }
    }
};
</script>