export function passGen(len = 6) {
  //const chars = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
  const chars = '123456789';
  let result = '';

  for (let i = 0; i < len; i++) {
    let pos = Math.floor(Math.random() * chars.length);

    result += chars.substring(pos, pos + 1);
  }

  return result;
}


export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export const findInTree = (root, id, property = 'id') => {
  var stack = [], node, ii;
  stack.push(root);
  while (stack.length > 0) {
    node = stack.pop();
    if (node[property] == id) {
      return node;
    } else if (node.children && node.children.length) {
      for (ii = 0; ii < node.children.length; ii += 1) {
        stack.push(node.children[ii]);
      }
    }
  }
  return null;
}

export const lockOrientationUniversal = (mode) => window.screen.orientation && window.screen.orientation.lock(mode).then(() => {}, err => console.log(err)) || window.screen.mozLockOrientation && window.screen.mozLockOrientation(mode) || window.screen.msLockOrientation && window.screen.msLockOrientation(mode);


// write a function that compares versions like "1.0.0.68" and "1.0.0.69" and returns -1, 0, or 1
export function checkVersion(version1, version2) {
  // Split the version strings into arrays of integers
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  // Compare corresponding parts of the versions
  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1 = v1Parts[i] || 0; // Default to 0 if part is missing
      const v2 = v2Parts[i] || 0; // Default to 0 if part is missing

      if (v1 < v2) {
          return false;
      } else if (v1 > v2) {
          return true;
      }
  }

  // If all parts are equal, return true
  return true;
}