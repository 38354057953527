<template>
  <div
    v-if="item.type === 'division'"
    class="item"
    :class="{ selected: $route.params.division === item.divisionId }"
    @click="selectDivision(item)"
    @mouseover="$refs.text.startScrolling()"
    @mouseleave="$refs.text.stopScrolling()"
  >
    <span class="mdi division-icon mdi-home"></span>
    <span class="name division-name"
      ><ScrollingText ref="text">{{ item.name }}</ScrollingText></span
    >
    <i
      class="mdi arrow mdi-chevron-down"
      @click.stop="toggle"
      :style="{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }"
    ></i>
  </div>
</template>
<script>
import ScrollingText from '@/components/ScrollingText.vue';
export default {
  name: 'Division',
  components: {
    ScrollingText
  },
  props: ['item', 'toggle', 'isOpen', 'isLoading', 'refetchData', 'parent', 'loadChildren', 'children'],
  emits: ['selectDivision'],
  methods: {
    selectDivision(item) {
      this.$emit('selectDivision', item);
    },
  },
}
</script>
<style scoped>
.item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
}

.item:hover {
    background-color: #464646;
}

.selected {
    background-color: #389af0;
}
.division-icon {
    font-size: 30px;
}

.division-name {
    font-size: 18px;
    margin-left: 10px;
    overflow: hidden;
}
.arrow {
    font-size: 30px;
    margin-left: auto;
    transition: transform 0.2s linear;
}
</style>