<template>
  <div @touchstart="toggleFullscreen" ref="videoWrap">
    <video class="cam-player video-js vjs-default-skin vjs-big-play-centered" ref="video" v-bind="$attrs"
           webkit-playsinline playsinline preload="metadata" autoplay controls muted></video>
  </div>
</template>

<script>

import videojs from "video.js";
import moment from "moment";
import { toRaw } from "vue";

let timeOutResource = null;

const PLAYBACK_RATES = [1, 2, 4, 8, 16];

export default {
  name: "CamPlayer",

  props: {
    source: {
      type: String,
      required: true
    },
    poster: {
      type: String
    },
    showTimer: {
      type: Boolean
    },

    isAutoplay: {
      type: Boolean
    },
    hasPlaybackRates: {
      type: Boolean,
      default: false
    },
    canZoom: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      player: null,
      currentItem: null,
      currentTime: 0
    };
  },

  watch: {
    source() {
      this.initialize();
    }
  },

  mounted() {
    this.initialize();
  },

  beforeUnmount() {
    this.player.dispose();

    document.body.removeEventListener("keyup", this.keyPress);
  },

  methods: {
    toggleFullscreen() {
      try {
        if (timeOutResource) {
          //document.querySelector('.vjs-play-control').click();
          this.$refs['videoWrap'].querySelector('.vjs-fullscreen-control').click();
        }

        timeOutResource = setTimeout(() => {
          clearTimeout(timeOutResource);
          timeOutResource = null
        }, 200)
      } catch (e) {
        console.dir(e)
      }
    },

    initialize() {
      const playerOptions = {
        audioDisabled: true,
        loaderType: 'main',
        vhs: {
          handlePartialData: true
        },
        html5: {
          vhs: {
            overrideNative: true,
            handlePartialData: true
          },
        }
      };

      if (this.hasPlaybackRates) {
        playerOptions.playbackRates = PLAYBACK_RATES;
      }
      this.player = videojs(this.$refs["video"], playerOptions, () => {
      });
      const noProxyPlayer = toRaw(this.player);
      window.player = noProxyPlayer;
      // NOTE: сейчас мы не следим за реактивностью source
      //   если это надо, то нужно использовать watch
      noProxyPlayer.src({
        src: this.source,
        type: 'application/x-mpegURL'
      });

      if (this.poster) {
        noProxyPlayer.poster(this.poster);
      } else {
        noProxyPlayer.poster("./img/poster.png");
      }

      this.$nextTick(() => {
        if (this.canZoom) {
          noProxyPlayer.zoomPlugin(noProxyPlayer);
        }
        noProxyPlayer.textTracks().on("addtrack", e => {
          //if (this.showTimer) {
          let track = e.track;
          if (track.kind === "metadata") {
            if (!track.on) {
              return;
            }

            track.on("cuechange", () => {
              let uri = "";
              if (track.activeCues[0]) {
                uri = track.activeCues[0].value.uri;
                this.setVideoTimer(uri);
              }
            });
          }
          //}
        });

        noProxyPlayer.on("durationchange", () => {
          if (!this.isAutoplay) {
            setTimeout(() => {
              noProxyPlayer.pause();
            }, 1000);
          }
        });

        noProxyPlayer.on("pause", () => {
          this.$emit("pause");
        });

        noProxyPlayer.on("play", () => {
          this.$emit("play");
        });

        noProxyPlayer.on("timeupdate", event => {
          const { target: videoWrapper } = event;
          if (typeof videoWrapper.querySelector !== "function") return;

          const video = videoWrapper.querySelector("video");

          const currentTime = Math.floor(video.currentTime);
          if (this.currentTime === currentTime) {
            return;
          }

          this.currentTime = currentTime;
          this.$emit("update:current-time", currentTime);
        });

        noProxyPlayer.on("ended", event => {
          this.$emit("ended", event);
        });

        document.body.addEventListener("keyup", this.keyPress);
      });
    },

    setVideoTimer(uri) {
      let videotime = uri
        .split("/")
        .pop()
        .split("-")
        .pop()
        .split(".")
        .shift();
      let date = moment(videotime, "YYYYMMDDHHmmss");
      this.$emit("onVideoTime", date);
    },

    setTime(time) {
      this.currentTime = time;
      toRaw(this.player).currentTime(time);
    },

    keyPress(event) {
      if (event.key == " ") {
        if (toRaw(this.player).paused()) {
          toRaw(this.player).play();
        } else {
          toRaw(this.player).pause();
        }
      }
      if (event.key === "ArrowLeft") {
        var currentTime = toRaw(this.player).currentTime();
        var subtractTime = 15;

        if (currentTime > 0) {
          currentTime -= subtractTime;
          toRaw(this.player).currentTime(currentTime);
        }
      }
      if (event.key === "ArrowRight") {
        var curTime = toRaw(this.player).currentTime();
        const duration = toRaw(this.player).duration();
        var addTime = 15;

        if (curTime < duration) {
          curTime += addTime;
          toRaw(this.player).currentTime(curTime);
        }
      }
    }
  }
};
</script>

<style lang="stylus">
.cam-player {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.video-js .vjs-big-play-button {
  top: 50%;
  margin: -1em 0 0 -1.5em;
  left: 50%;
}

.vjs-has-started .vjs-poster {
  display: block;
  opacity: 0;
  pointer-events: none;
}

.vjs-zoom-drag .vjs-poster {
  pointer-events: auto;
}

.vjs-paused {
  .vjs-big-play-button {
    // display: block
  }
}

.vjs-zoom-button .vjs-icon-placeholder::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='%23fff'%3E%3Cpath d='M8.5 5.87H7.04V4.42a.6.6 0 00-.6-.6.6.6 0 00-.6.6v1.45H4.41a.6.6 0 000 1.2h1.44V8.5c0 .34.27.6.6.6a.6.6 0 00.6-.6V7.06h1.44a.6.6 0 000-1.2zm0 0'/%3E%3Cpath d='M6.45 11.39a4.93 4.93 0 110-9.86 4.93 4.93 0 010 9.86zm8.05 2.28l-3.32-3.32a6.13 6.13 0 10-.84.85l3.32 3.32c.15.16.62.23.84 0a.6.6 0 000-.85zm0 0'/%3E%3C/svg%3E");
  background-repeat no-repeat;
  background-position: center;
}
</style>
