import { ApiSys } from "./api";
import { useDefaultStore } from "../store";


export const Favorites = {
  async init() {
    const store = useDefaultStore();
    const { data } = await ApiSys.getFavorites();

    if (data) {
      store.setFavoriteList(data);
    }
  },

  async _addObServer({ division, cam }) {
    try {
      await ApiSys.addToFavorite({
        division,
        cam
      });

      return true;
    } catch (e) {
      return false;
    }
  },

  async _deleteObServer({ division, cam }) {
    try {
      await ApiSys.deleteFromFavorite({
        division,
        cam
      });

      return true;
    } catch (e) {
      return false;
    }
  },

  async add({ division, cam }) {
    const addResult = await this._addObServer({ division, cam });
    const store = useDefaultStore();
    if (!addResult) {
      return;
    }

    const foundDivision = store.favoriteList.find(e => e.division === division);

    if (foundDivision) {
      const foundSame = store.favoriteList.find(e => e.division === division && e.cam === cam);

      if (!foundSame) {
        store.setFavoriteList([
          ...store.favoriteList,
          {
            division,
            cam
          }
        ]);
      }
    } else {
      store.setFavoriteList([
        ...store.favoriteList,
        {
          division,
          cam
        }
      ]);
    }
  },

  async remove({ division, cam }) {
    const store = useDefaultStore();
    const deleteResult = await this._deleteObServer({ division, cam });

    if (!deleteResult) {
      return;
    }

    const copyFav = JSON.parse(JSON.stringify(store.favoriteList));

    const foundSame = copyFav.findIndex(e => e.division === division && e.cam === cam);

    if (foundSame + 1 >= 0) {
      copyFav.splice(foundSame, 1);

      store.setFavoriteList(copyFav);
    }
  }
};
