
export default function formatTime(time, withSeconds = false) {
  const SECS_IN_MIN = 60;
  const SECS_IN_HOUR = 60 * SECS_IN_MIN;

  const hours = Math.floor(time / SECS_IN_HOUR);
  const mins = Math.floor((time - hours * SECS_IN_HOUR) / SECS_IN_MIN);
  const formattedMins = String(mins).padStart(2, '0');

  if (!withSeconds) {
    return `${hours}:${formattedMins}`;
  }

  const seconds = time - hours * SECS_IN_HOUR - mins * SECS_IN_MIN;
  const formattedSeconds = String(seconds).padStart(2, '0');
  
  return `${hours}:${formattedMins}:${formattedSeconds}`;
};
