export class VideoZoom {
  parent = null
  element = null
  i = 0
  scale = 1
  initialScale = 1
  elementRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  }
  touchstartTime = 0
  eventType = undefined
  startX = 0
  startY = 0
  moveX = 0
  moveY = 0
  initialMoveX = 0
  initialMoveY = 0
  moveXC = 0
  moveYC = 0
  lastTap = 0
  draggingMode = false
  distance = 0
  doubleTapTimeout = 0
  initialDistance = 0
  events = {}
  maxHtmlContentScale = 3
  maxScale = 3
  doubleTapMinTimeout = 300
  tapMinTimeout = 200
  isMousedown = false
  swipeThreshold = 50
  swipeDone = false
  swipeDir = undefined
  isTouchscreen = false
  touchListeners = {
    touchstart: 'handleTouchstart',
    touchmove: 'handleTouchmove',
    touchend: 'handleTouchend'
  }
  mouseListeners = {
    mousedown: 'handleMousedown',
    mousemove: 'handleMousemove',
    mouseup: 'handleMouseup',
    wheel: 'handleWheel'
  }
  otherListeners = {
    resize: 'handleResize'
  }
  emits = {}
  constructor() {}

  init(parent, element) {
    this.parent = parent
    this.element = element
    this.getElementRect()
    this.toggleEventListeners('addEventListener');
    this.isTouchscreen = this.detectTouchScreen();
  }

  addEventHandler(event, handler) {
    this.emits[event] = handler
  }

  removeEventHandler(event) {
    delete this.emits[event]
  }

  emit(event, data = null) {
    console.log(event, data, this.emits)
    if (this.emits[event]) {
      if (data) {
        this.emits[event](data)
      } else {
        this.emits[event]()
      }
    }
  }
  dispose() {
    this.toggleEventListeners('removeEventListener')
  }

  toggleEventListeners(action) {
    const listeners = { ...this.touchListeners, ...this.mouseListeners, ...this.otherListeners }

    for (const listener in listeners) {
      const handler = listeners[listener]
      // Window
      if (listener === 'resize') {
        if (action === 'addEventListener') {
          window.addEventListener(listener, this[handler], false)
        }
        if (action === 'removeEventListener') {
          window.removeEventListener(listener, this[handler], false)
        }
        // Document
      } else if (listener === 'mouseup' || listener === 'mousemove') {
        if (action === 'addEventListener') {
          document.addEventListener(listener, this[handler], false)
        }
        if (action === 'removeEventListener') {
          document.removeEventListener(listener, this[handler], false)
        }
        // Element
      } else {
        if (action === 'addEventListener') {
          this.parent.addEventListener(listener, this[handler], false)
        }
        if (action === 'removeEventListener') {
          this.parent.removeEventListener(listener, this[handler], false)
        }
      }
    }
  }

  getElementRect = () => {
    this.elementRect = this.parent.getBoundingClientRect()
    this.swipeThreshold = this.elementRect.width / 2
  }

  setBasicStyles = () => {
    this.element.style.display = 'flex'
    this.element.style.alignItems = 'center'
    this.element.style.justifyContent = 'center'
    this.element.style.transformOrigin = '0 0'
    this.element.style.maxWidth = '100%'
    this.element.style.maxHeight = '100%'
  }

  handleResize = () => {
    this.getElementRect()
  }

  handleWheel = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleTouchstart = (event) => {
    this.getElementRect()
    this.touchstartTime = new Date().getTime()
    this.eventType = undefined
    this.swipeDone = false
    this.getTouchstartPosition(event)
  }
  getTouchstartPosition(event) {
    this.startX = event.touches[0].clientX - this.elementRect.left
    this.startY = event.touches[0].clientY - this.elementRect.top
  }

  handleTouchmove = (event) => {
    const touches = event.touches

    // Pan
    if (this.detectPan(touches)) {
      this.runHandler('pan', event)
    }

    // Pinch
    if (this.detectPinch(touches)) {
      this.runHandler('pinch', event)
    }

    // Swipe
    this.detectSwipe(touches)
  }

  detectPan(touches) {
    return (touches.length === 1 && !this.eventType) || this.eventType === 'pan'
  }

  detectPinch(touches) {
    return (touches.length === 2 && this.eventType === undefined) || this.eventType === 'pinch'
  }

  detectTouchScreen() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
    var mq = function (query) {
      return window.matchMedia(query).matches
    }

    if ('ontouchstart' in window) {
      return true
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
    return mq(query)
  }
  detectSwipe(touches) {
    if (
      (touches.length > 1 || !touches.length) &&
      this.eventType === undefined &&
      this.scale !== 1
    ) {
      return false
    }
    const movementX = Math.abs(touches[0].clientX - this.startX)
    if (movementX > this.swipeThreshold) {
      this.dir = Math.sign(touches[0].clientX - this.startX) > 0 ? 'left' : 'right'
      return true
    } else {
      this.dir = undefined
      return false
    }
  }

  handleTouchend = (event) => {
    const touches = event.touches
    this.i = 0
    this.draggingMode = false
    // Double Tap
    if (this.detectDoubleTap()) {
      this.runHandler('double-tap', event)
    }

    // Tap
    this.detectTap()

    if (this.dir) {
      this.emit('swipe', this.dir)
    }

    // Min scale
    if (this.scale < 1) {
      this.scale = 1
    }

    // Update initial values
    if (this.eventType === 'pinch' || this.eventType === 'pan' || this.eventType === 'swipe') {
      this.updateInitialValues()
    }

    this.eventType = 'touchend'

    if (touches && touches.length === 0) {
      this.eventType = undefined
    }

    /* mouseup */
    if (event.type === 'mouseup') {
      this.draggingMode = false
      this.updateInitialValues()
      this.eventType = undefined
    }
    this.runHandler('touchend', event)
    this.eventType = 'touchend'

    if (touches && touches.length === 0) {
      this.eventType = undefined
      this.i = 0
    }
  }

  detectDoubleTap() {
    if (this.eventType != undefined) {
      return
    }

    const currentTime = new Date().getTime()
    const tapLength = currentTime - this.lastTap

    clearTimeout(this.doubleTapTimeout)

    if (tapLength < this.doubleTapMinTimeout && tapLength > 0) {
      return true
    } else {
      this.doubleTapTimeout = setTimeout(() => {
        clearTimeout(this.doubleTapTimeout)
      }, this.doubleTapMinTimeout)
    }
    this.lastTap = currentTime
  }

  detectTap() {
    if (this.eventType != undefined) {
      return
    }

    const currentTime = new Date().getTime()
    const tapLength = currentTime - this.touchstartTime

    if (tapLength > 0) {
      if (tapLength < this.tapMinTimeout) {
        this.runHandler('tap', event)
      } else {
        this.runHandler('longtap', event)
      }
    }
  }

  handleMousedown = (event) => {
    this.isMousedown = true
    this.getElementRect()
    this.touchstartTime = new Date().getTime()

    if (this.eventType === undefined) {
      this.getMousedownPosition(event)
    }

    this.runHandler('mousedown', event)
  }

  getMousedownPosition(event) {
    this.startX = event.clientX - this.elementRect.left
    this.startY = event.clientY - this.elementRect.top
  }

  handleMousemove = (event) => {
    if (!this.isMousedown) {
      return
    }

    this.runHandler('pan', event)
  }

  handleMouseup = (event) => {
    // Tap
    this.detectTap()

    this.isMousedown = false
    this.runHandler('mouseup', event)
    // Update initial values
    if (
      this.eventType === 'pinch' ||
      this.eventType === 'pan' ||
      this.eventType === 'horizontal-swipe' ||
      this.eventType === 'vertical-swipe'
    ) {
      this.updateInitialValues()
    }
    this.eventType = undefined
    this.i = 0
  }

  runHandler(eventName, event) {
    if (eventName === 'pinch') {
      this.handlePinch(event)
    }

    if (eventName === 'pan') {
      this.handlePan(event)
    }
  }

  transformElement(duration) {
    this.element.style.transition = 'all ' + duration + 'ms'
    this.element.style.transform =
      'matrix(' +
      Number(this.scale) +
      ', 0, 0, ' +
      Number(this.scale) +
      ', ' +
      Number(this.moveX) +
      ', ' +
      Number(this.moveY) +
      ')'
  }

  getClientPosition(event, index = 0) {
    let clientX
    let clientY

    if (event.type === 'touchstart' || event.type === 'touchmove') {
      clientX = event.touches[index].clientX
      clientY = event.touches[index].clientY
    }
    if (event.type === 'mousedown' || event.type === 'mousemove') {
      clientX = event.clientX
      clientY = event.clientY
    }

    return {
      clientX,
      clientY
    }
  }

  moveLeft(event, index = 0) {
    const clientX = this.getClientPosition(event, index).clientX
    return clientX - this.elementRect.left
  }

  moveTop(event, index = 0) {
    const clientY = this.getClientPosition(event, index).clientY
    return clientY - this.elementRect.top
  }

  getDistance(touches) {
    return Math.sqrt(
      Math.pow(touches[0].pageX - touches[1].pageX, 2) +
        Math.pow(touches[0].pageY - touches[1].pageY, 2)
    )
  }

  limitPanX() {
    const elementWidth = this.element.offsetWidth
    const scaledWidth = elementWidth * this.scale
    const parentWidth = this.parent.offsetWidth
    if (scaledWidth < parentWidth) {
      this.moveX = (parentWidth - elementWidth * this.scale) / 2
    } else {
      const offsetLeft = (scaledWidth - parentWidth) / 2

      if (this.moveX > offsetLeft) {
        this.moveX = offsetLeft
      } else if (this.moveX < -offsetLeft) {
        this.moveX = -offsetLeft
      }
    }
  }

  limitPanY() {
    const elementHeight = this.element.offsetHeight
    const scaledHeight = elementHeight * this.scale
    const parentHeight = this.parent.offsetHeight
    if (scaledHeight < parentHeight) {
      this.moveY = (parentHeight - scaledHeight * this.scale) / 2
    } else {
      const offsetTop = (scaledHeight - parentHeight) / 2
      if (this.moveY > offsetTop) {
        this.moveY = offsetTop
      } else if (Math.sign(this.moveY) === -1 && this.moveY < -offsetTop) {
        this.moveY = -offsetTop
      }
    }
  }

  centering() {
    const initialMoveX = this.moveX
    const initialMoveY = this.moveY

    if (this.moveY > 0) {
      this.moveY = 0
    }
    if (this.moveX > 0) {
      this.moveX = 0
    }

    if (this.element) {
      this.limitPanY()
      this.limitPanX()
    }
    if (this.element && this.scale < 1) {
      if (this.moveX < this.element.offsetWidth * (1 - this.scale)) {
        this.moveX = this.element.offsetWidth * (1 - this.scale)
      }
    }

    return initialMoveX !== this.moveX || initialMoveY !== this.moveY
  }

  handleLimitZoom() {
    const limitZoom = this.maxScale
    const minScale = 1

    if (this.scale > limitZoom || this.scale <= minScale) {
      if (this.scale > limitZoom) {
        this.scale = limitZoom
      }

      if (this.scale <= minScale) {
        this.scale = minScale
      }
    }
  }
  resetScale() {
    this.scale = 1
    this.moveX = 0
    this.moveY = 0
    this.updateInitialValues()
    this.transformElement(100)
  }

  updateInitialValues() {
    this.initialScale = this.scale
    this.initialMoveX = this.moveX
    this.initialMoveY = this.moveY
  }
  handlePinch = (event) => {
    event.preventDefault()

    if (this.eventType === undefined || this.eventType === 'pinch') {
      const touches = event.touches
      if (!this.eventType) {
        this.initialDistance = this.getDistance(touches)
        const moveLeft0 = this.moveLeft(event, 0)
        const moveLeft1 = this.moveLeft(event, 1)
        const moveTop0 = this.moveTop(event, 0)
        const moveTop1 = this.moveTop(event, 1)

        this.moveXC = moveLeft0 + moveLeft1 - this.initialMoveX
        this.moveYC = moveTop0 + moveTop1 - this.initialMoveY
      }

      this.eventType = 'pinch'
      this.distance = this.getDistance(touches)
      this.scale = this.initialScale * (this.distance / this.initialDistance)
      this.moveX =
        this.initialMoveX - ((this.distance / this.initialDistance) * this.moveXC - this.moveXC)
      this.moveY =
        this.initialMoveY - ((this.distance / this.initialDistance) * this.moveYC - this.moveYC)
      this.handleLimitZoom()

      this.limitPanY()
      this.limitPanX()

      this.transformElement(0)
    }
  }
  handlePan = (event) => {
    if (this.scale <= 1) {
      return
    }

    event.preventDefault()
    const { clientX, clientY } = this.getClientPosition(event)

    if (!this.eventType) {
      this.startX = clientX - this.elementRect.left
      this.startY = clientY - this.elementRect.top
    }

    this.eventType = 'pan'
    this.moveX = this.initialMoveX + (this.moveLeft(event, 0) - this.startX)
    this.moveY = this.initialMoveY + (this.moveTop(event, 0) - this.startY)

    this.limitPanY()
    this.limitPanX()

    this.transformElement(0)
  }
}
