<template>
  <li>
    <component
      v-if="components[item.type] || components['default']"
      :is="components[item.type] || components['default']"
      :item="item"
      :isOpen="isOpen"
      :toggle="toggle"
      :open="open"
      :loadChildren="loadChildren"
      :children="children"
      :isLoading="isLoading"
      :refetchData="refetchData"
      :parent="parent"
    />
    <TransitionExpand :numElements="children.length">
      <div v-if="isOpen && hasChildren" class="children">
        <ul>
          <TreeItem
            v-for="node in children"
            :key="node.key"
            :item="node"
            :components="components"
            :lazy="lazy"
            :refetch="refetch"
            :parent="this"
          >
          </TreeItem>
        </ul>
      </div>
    </TransitionExpand>
  </li>
</template>

<script>
import TransitionExpand from './TransitionExpand.vue'
import { inject } from 'vue'
import { generateKeys } from './utils'
export default {
  name: 'TreeItem',
  components: { TransitionExpand },
  props: {
    item: {
      type: Object,
      required: true
    },
    components: {
      type: Object,
      default: () => {
        return {}
      }
    },
    lazy: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refetch: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parent: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  setup() {
    const expandedKeys = inject('treeKeys')
    return { expandedKeys }
  },
  data() {
    return {
      defaultChildren: this.item.children ?? [],
      loadedChildren: [],
      isLoading: false
    }
  },
  watch: {
    async isOpen() {
      if (this.isOpen) {
        await this.loadChildren()
      }
    }
  },
  computed: {
    hasChildren() {
      return this.children && this.children.length
    },
    isOpen() {
      return this.expandedKeys[this.item.key] ?? false
    },
    children() {
      return [...this.defaultChildren, ...this.loadedChildren]
    }
  },
  methods: {
    async toggle() {
      await this.loadChildren()
      if (this.hasChildren) {
        this.expandedKeys[this.item.key] = !this.expandedKeys[this.item.key] ?? true
      }
    },
    async open() {
      await this.loadChildren()
      if (this.hasChildren) {
        this.expandedKeys[this.item.key] = true
      }
    },
    async loadChildren() {
      const fetchFunction = this.lazy[this.item.type]
      if (!fetchFunction || this.loadedChildren.length !== 0 || this.loading) return
      this.isLoading = true
      const result = await fetchFunction(this.item)
      this.loadedChildren = generateKeys(result, this.item.key, this.defaultChildren.length);
      this.isLoading = false
    },

    async refetchData() {
      const refetchFunction = this.refetch[this.item.type]
      if (!refetchFunction || this.loading) return
      this.loadedChildren = []
      this.isLoading = true
      const result = await refetchFunction(this.item)
      this.defaultChildren = generateKeys(result.children, this.item.key)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.item {
  cursor: pointer;
  padding: 5px;
  display: block;
}

.children {
  margin-left: 20px;
  display: block;
}
ul {
  padding: 0;
}
</style>
