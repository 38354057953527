<template>
  <div id="Auth">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script lang="js">
export default {
  name: 'Auth'
};
</script>
