import { createWebHistory, createRouter } from 'vue-router'

import Cams from '@/views/Cams.vue';
import CamList from '@/views/Cams/CamList.vue';
import CamView from '@/views/Cams/CamView.vue';
import CamArchive from '@/views/Cams/CamArchive.vue';
import Favorite from '@/views/Cams/Favorite.vue';

import Auth from '@/views/Auth.vue';
import Login from '@/views/Auth/Login.vue';

import Admin from '@/views/Admin.vue';
import Editor from '@/views/Admin/Editor.vue';
import { useDefaultStore } from './store';
import Error404 from '@/views/Error404.vue';

import CamArchiveNew from './views/Cams/CamArchiveNew.vue';
import CamListNew from './views/Cams/CamListNew.vue';
import CamViewNew from './views/Cams/CamViewNew.vue';
import TimelineNew from './components/cam/TimelineNew.vue';
import { checkVersion } from './helpers/utils';

const routes = [
  {
    path: '/',
    component: Cams,
    children: [
      {
        path: '/testplayer',
        name: 'player-test',
        component: TimelineNew
      },
      {
        path: '/cam-list/:division?',
        name: 'cam-list',
        component: CamList,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam-listNew/:division?',
        name: 'cam-listNew',
        component: CamListNew,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam/:cam?/:division?',
        name: 'cam',
        component: CamView,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam-new/:cam?/:division?',
        name: 'cam-new',
        component: CamViewNew,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam-archive/:cam?/:division?',
        name: 'cam-archive',
        component: CamArchive,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam-archive-new/:cam?/:division?',
        name: 'cam-archive-new',
        component: CamArchiveNew,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/cam-archive-all/:division?/:all',
        name: 'cam-archive-all-cams',
        component: CamArchive,
        meta: { transition: 'fade-in-down' }
      },
      {
        path: '/favorite',
        name: 'cam-favorites',
        component: Favorite,
        meta: { transition: 'fade-in-down' }
      }
    ]
  },
  // {
  //   path: '/favorite',
  //   component: Favorite,
  //   meta: { transition: 'fade-in-down' }
  // },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: Login,
        meta: { transition: 'fade-in-down' }
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '/admin/editor',
        name: 'editor',
        component: Editor,
        meta: {
          transition: 'fade-in-down',
          admin: true
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: Error404
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})


// Weird WebView hack, on some devices routing does not work and router.push throws,
// rendering app unusable.
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err));
};


router.beforeEach(async (to, from, next) => {
  const store = useDefaultStore();
  if (to.name === 'cam-list' || to.name === 'cam' || to.name === 'cam-archive' || to.name === 'cam-archive-new') {
    if (to.params.division) {
      store.currentDivision = store.allDivisions.find(el => { return el.guid === to.params.division });
    }
  }
  
  if (to.name === 'cam-list' && !to.params.division) {
    const division = store.allDivisions.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)[0];
    if (division) {
      store.currentDivision = division;
      next({ name: 'cam-list', params: { division: division.guid } })
      return;
    }
  }
  if (to.name !== 'login' && localStorage.token == "") next({ name: 'login' })
  else next();
})
