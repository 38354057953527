<template>
  <div id="FavoriteList">
    <div class="main-content" v-if="allDivisions.length">
      <template v-for="(division, index) of favGroups" :key="index">
        <div class="division-title">{{ currentDivision(division[0].division).name }}</div>
        <div class="cams-grid">
          <a v-for="cam of division" class="cam-layout" :class="`cam-grid-layout-${gridCam}`" :key="cam.cam" href="#"
             @click.prevent>
            <div class="cam-container">
              <CamPlayer class="cam" :show-timer="false" :is-autoplay="autoPlay && !smallScreen"
                         :source="getCamSourceUrl(cam.cam, division[0].division)"
                         @click.prevent="routeToCam(cam.cam, division[0].division)" />
              <!--<div v-if="showTimer" class="cam-time">
                <timer v-model="timer"/>
              </div>-->
              <div v-if="showCamName" class="cam-name">
                {{ cam.cam }}
                <div class="cam-favorite" @click="handlerFavorite(cam.cam, division[0].division)">
                  <span class="mdi mdi-star"
                        :style="{ 'color': isInFavorite(cam.cam, division[0].division) ? 'red' : '#fff' }"></span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CamPlayer from "@/components/CamPlayer.vue";
import * as _ from 'lodash-es';
import { useDefaultStore } from "@/store";
import { Favorites } from "../../services/favorites";
import { ApiSys } from "../../services/api";
import { isIOS } from '@/helpers/utils';
export default {
  name: "Favorite",

  components: {
    CamPlayer
  },
  data() {
    return {
      allDivisions: [],
    };
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    autoPlay() {
      return !isIOS();
    },

    inFavoriteList() {
      return this.store.favoriteList;
    },

    favList() {
      return this.store.favoriteList;
    },
    isHideSidebar() {
      return this.store.isHideSidebar;
    },
    smallScreen() {
      return this.store.smallScreen;
    },
    showTimer() {
      return this.store.showTimer;
    },
    showCamName() {
      return this.store.showCamName;
    },

    gridCam() {
      return this.store.gridCam;
    },

    gridSize() {
      return this.gridCam ** 2;
    },

    fullScreen() {
      return this.store.fullScreen;
    },

    favGroups() {
      return _.groupBy(this.favList, 'division')
    },
  },
  methods: {
    isInFavorite(cam, divisionGuid) {
      return this.inFavoriteList.find(e => e.division === this.currentDivision(divisionGuid).uuid && e.cam === cam)
    },

    async handlerFavorite(camName, divisionGuid) {
      const inFav = this.isInFavorite(camName, divisionGuid);

      if (inFav) {
        this.removeFromFavorite(camName, divisionGuid);
      } else {
        this.addToFavorite(camName, divisionGuid);
      }
    },

    async addToFavorite(cam, divisionGuid) {
      try {
        await Favorites.add({
          division: this.currentDivision(divisionGuid).uuid,
          cam
        });
        this.$toast.success('Камера добавлена в избранное');
      } catch (e) {
        //
      }
    },

    async removeFromFavorite(cam, divisionGuid) {
      try {
        await Favorites.remove({
          division: this.currentDivision(divisionGuid).uuid,
          cam
        });
        this.$toast.success('Камера убрана из избранного');
      } catch (e) {
        //
      }
    },

    currentDivision(guid) {
      const foundDivision = this.allDivisions.find(d => d.uuid === guid);

      if (foundDivision) {
        return foundDivision
      }

      return {};
    },

    async getDivisions() {
      let result = await ApiSys.getDivisions();

      if (result.error) {
        this.$toast.error(result.error.message);
        this.allDivisions = [];
      } else {
        this.allDivisions = result.data;
      }
    },

    routeToCam(camName, divisionGuid) {
      this.$router.push({
        name: "cam",
        params: { cam: camName, division: this.currentDivision(divisionGuid).uuid }
      });
    },

    getCamSourceUrl(cam, divisionGuid) {
      const division = this.store.allDivisions.find((d) => d.guid === divisionGuid);
      if (window.location.protocol == "http:")
        return division
          ? `http://${division.address}/${division.guid}/${cam}/m/index.m3u8`
          : undefined;
      return division
        ? `https://${division.guid}.pervaya1.ru/${division.guid}/${cam}/m/index.m3u8`
        : undefined;
    },
  },
  async created() {
    await this.getDivisions();

    this.store.setPageTitle("Избранное");
  },
};
</script>

<style lang="stylus">

  #FavoriteList {
    width: 100%;
    overflow: auto
    height calc(100% - 40px)
    position: relative

    .main-content {
      max-width 900px;
      margin 0 auto;
      padding: 0;
      height: 100%;
      overflow: auto;
    }

    .division-title {
      padding 10px
    }

    &.has-pagination {
      padding-bottom: 50px;

      &.has-pagination_fullscreen {
        padding-bottom: 0;
        height: 100%;
      }
    }

    .cam-layout {
      position: relative;
      margin: 0;
      background: #151515;
      display: block;
    }

    .cams-grid {
      position relative
      display: flex
      flex-wrap wrap
      justify-content flex-start
      align-items flex-start
      overflow-x: hidden
      overflow-y: auto
      width: 100%
      height initial
      padding: 10px

      .cam-container {
        position: relative;
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        height: auto;
        min-height: 260px;

        .cam {
          width: 100%;
          height: 100%;
          /*height: -webkit-fill-available;*/
        }
      }

      .cam-grid-layout-3 {
        width 33.3333%

        .cam-container {
          min-height: 260px;
        }
      }

      .cam-grid-layout-4 {
        width 25%

        .cam-container {
          min-height: 200px;
        }
      }

      .cam-grid-layout-5 {
        width 20%

        .cam-container {
          min-height: 160px;
        }
      }

      .cam-grid-layout-6 {
        width 16.6666%

        .cam-container {
          min-height: 120px;
        }
      }

      .cam-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #272c33;
        position: absolute;
        height: 20px;
        bottom: 0;
        width: 100%;
        color: #fff;
        padding: 15px 10px 15px 10px;
        font-size: 14px;
        line-height: 0;
        opacity: 0.8;
        cursor: pointer;
      }

      .cam-favorite {
        cursor: pointer;
        display: inline
        padding 0 6px
        font-size 20px
      }
    }

    .ghost {
      border-left: 6px solid rgb(0, 183, 255);
      box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
      opacity: 0.7;
    }

    .sortable-drag {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    #FavoriteList .cam-layout {
      width: 100% !important;
    }
  }
</style>
