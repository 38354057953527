<template>
  <input class="form-control time-input" v-model="time" v-maska data-maska="##:##:##" />
</template>

<script>
import { vMaska } from "maska"
export default {
  name: 'TimeInput',
  directives: { maska: vMaska },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  components: {
  },
  props: {
    modelValue: {
      type: String,
      default: '00:00:00'
    }
  },
  computed: {
    time: {
      get() {
        return this.modelValue;
      },
      set(time) {
        this.$emit('update:modelValue', time);
      }
    }
  },
};
</script>

<style>
.time-input {
  padding: 5px 10px;
}
</style>
