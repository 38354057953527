<template>
    <div v-if="loading" class="row">
        <div class="loader"></div>
    </div>
    <CTabContent v-if="!loading">
        <CTabPane role="tabpanel" :visible="tabPaneActiveKey === 1">
            <div class="box">
                <div class="box-header">
                    <h3 class="text-white" v-if="division">{{ division.name }}</h3>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12 small">
                    <CButtonGroup size="sm">
                        <CButton color="primary" @click="checkAll(true)">Выбрать все</CButton>
                        <CButton color="secondary" @click="checkAll(false)">Отменить все</CButton>
                    </CButtonGroup>
                </div>
            </div>

            <div class="row my-3" v-if="division">
                <div v-for="(cam, idxCam) of camList" :key="idxCam" class="col-6 mb-2 border-bottom border-dark">
                    <CFormCheck :id="cam.name" :label="cam.name" v-model="cam.selected" />
                </div>
            </div>

            <div class="row">
                <div class="col-12 flex-row justify-content-end">
                    <CButton color="success" class="flex-shrink" @click="goToUsers()">
                        Далее
                    </CButton>
                </div>
            </div>
        </CTabPane>
        <CTabPane role="tabpanel" :visible="tabPaneActiveKey === 2">
            <div class="box">
                <div class="box-header flex-column">
                    <CFormInput v-model="userFilter" placeholder="Поиск" autocomplete="off" />
                <div class="flex-row mt-2">
                    <CFormCheck inline id="onlythisdivision" label="Только в этой площадке" v-model="onlyThisDivision"/>
                    <CButtonGroup v-if="onlyThisDivision" size="sm">
                        <CButton color="primary" @click="checkAllUsers(true)">Выбрать всех</CButton>
                        <CButton color="secondary" @click="checkAllUsers(false)">Отменить всех</CButton>
                    </CButtonGroup>
                </div>
                </div>
            </div>
            <div class="row">
                <div v-for="(user, idx) of paginatedFilteredList" :key="idx" class="col-6 mb-2 border-bottom border-dark">
                    <CFormCheck :id="user.name" @change="checkUser(user)" :label="user.name" v-model="user.selected" />
                </div>
            </div>
            <div class="row">
                <Pagination :currentPage="page" :totalPages="Math.ceil(filteredSortedList.length / 12)"
                            @update:currentPage="(p) => { page = p }" :maxVisiblePages="5" />
            </div>
            <div class="row">
                <div class="col-6 flex-row justify-content-start">
                    <CButton class="flex-shrink" color="secondary" @click="tabPaneActiveKey--">
                        Назад
                    </CButton>
                </div>
                <div class="col-6 flex-row justify-content-end">
                    <CButton color="success" class="flex-shrink" @click="addCameras()">Добавить</CButton>
                </div>
            </div>
        </CTabPane>
    </CTabContent>
</template>
<script>
import { ApiSys } from '@/services/api';
import { CButton, CFormCheck, CTabContent, CTabPane, CFormInput, CButtonGroup } from '@coreui/bootstrap-vue';
import Pagination from '@/components/Pagination.vue';
export default {
    name: "AddCamerasToUsers",
    components: { CButton, CFormCheck, CTabContent, CTabPane, CFormInput, Pagination, CButtonGroup },
    props: {
        divisionId: {
            type: String,
        },
        hide: Function,
        users: Array
    },
    data() {
        return {
            division: null,
            camList: [],
            page: 1,
            userFilter: '',
            tabPaneActiveKey: 1,
            loading: true,
            allUsers: [],
            divisionUsers: [],
            onlyThisDivision: false,
            selectAllUsers: false,
            selectedSomeUsers: false
        }
    },
    watch: {
        userFilter() {
            this.page = 1;
        },
        onlyThisDivision() {
            this.page = 1;
        }
    },
    computed: {
        usersList() {
            if (this.onlyThisDivision) {
                const namesSet = new Set(this.divisionUsers.map(obj => obj.name));
                return this.allUsers.filter(obj => namesSet.has(obj.name))
            } else {
                return this.allUsers
            }
        },
        filteredSortedList() {
            return this.usersList
                .filter(u => u.name.toLowerCase().includes(this.userFilter.toLowerCase()))
        },
        paginatedFilteredList() {
            return this.filteredSortedList
                .slice((this.page - 1) * 12, this.page * 12)
        }
    },
    async created() {
        this.loading = true;
        this.division = await ApiSys.getAdminDivision(this.divisionId);
        this.camList = this.division.camList.map(e => { return { name: e, selected: false } });
        const users = await ApiSys.getAdminUsers();
        this.allUsers = users.map(e => { return { name: e.user, selected: false } });
        this.divisionUsers = this.users.map(e => { return { name: e.name, selected: false } });
        this.loading = false;
    },
    mounted() {
    },
    methods: {
        checkAll(value) {
            this.camList.forEach(e => {
                e.selected = value;
            });
        },
        checkAllUsers(value) {
            this.usersList.forEach(e => {
                e.selected = value;
            });
        },
        goToUsers() {
            if (this.camList.some(e => e.selected)) {
                this.tabPaneActiveKey = 2;
                return;
            }
            this.$toast.warning('Выберите камеры для добавления');
        },
        async addCameras() {
            const selected = this.usersList.filter(u => u.selected).map(u => u.name);
            if (selected.length == 0) {
                this.$toast.warning('Выберите пользователей для добавления');
                return;
            }
            let request = {
                users: selected,
                updateData: {
                    divisionswithcams: [{
                        divisionuuid: this.divisionId,
                        camlist: this.camList.filter(e => e.selected).map(e => e.name)
                    }],
                    divisionsop: "add"
                }
            };
            const result = await ApiSys.patchUsersAdmin(request);
            if (!result.error) {
                this.$toast.success('Камеры добавлены');
                this.hide();
            } else {
                this.$toast.error(result.error.message);
            }
        }
    }
}

</script>

<style scoped>
.loader {
    width: 64px;
    margin: auto;
    height: 64px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>