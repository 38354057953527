<template>
  <div class="editor-page">
    <div class="tree-wrap">

      <TreeView class="flex-grow" v-if="treeShow" :root="treeData"
                :refetch="{ 'group': loadGroupChildren }" :lazy="{ 'division': loadUsers }">
        <template #group="{ item, toggle, isOpen, isLoading, refetchData, parent }">
          <GroupItem :item="item" :toggle="toggle" :isOpen="isOpen" :isLoading="isLoading" :refetchData="refetchData"
                     :parent="parent" />
        </template>
        <template #division="{ item, toggle, isOpen, isLoading, refetchData, parent, loadChildren, children }">
          <DivisionItem :item="item" :toggle="toggle" :isOpen="isOpen" :isLoading="isLoading" :refetchData="refetchData"
                        :parent="parent" :loadChildren="loadChildren" :children="children" />
        </template>
        <template #user="{ item, toggle, isOpen, isLoading, refetchData, parent }">
          <UserItem :item="item" :toggle="toggle" :isOpen="isOpen" :isLoading="isLoading" :refetchData="refetchData"
                    :parent="parent" />
        </template>
      </TreeView>
      <span v-else class="loader"></span>
    </div>
  </div>
</template>

<script>
import { ApiSys } from "@/services/api";
import User from '@/services/user';
import GroupItem from "@/components/editor/EditorTree/GroupItem.vue";
import DivisionItem from "@/components/editor/EditorTree/DivisionItem.vue";
import UserItem from "@/components/editor/EditorTree/UserItem.vue";
import { useDefaultStore } from '@/store';
import TreeView from "@/components/TreeView";
import { provide } from "vue";
export default {
  name: "Editor",
  components: { UserItem, DivisionItem, GroupItem, TreeView },
  data() {
    return {
      loading: true,

      treeShow: false,

      treeData: [],
    }
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {

  },
  watch: {
  },
  methods: {
    async loadGroupChildren(item) {
      return ApiSys.getAdminGroup(item.id);
    },

    async loadUsers(item) {
      return ApiSys.getUsersInDivision(item.id);
    },

    async updateTree() {
      this.treeShow = false;
      this.treeData = await ApiSys.getAdminGroupTree();
      this.treeShow = true;
    },
  },
  async created() {
    // await this.getDivisions();
    this.updateTree();
    provide('updateTree', this.updateTree);
    this.store.setPageTitle('Редактор');
  }
}
</script>

<style lang="stylus" scoped>
  .editor-page {
    max-height 100%
    overflow auto
  }

  .tree-wrap {
    max-width 600px;
    margin 0 auto 0px
    padding 20px 0
    overflow-y auto
    max-height 100%
    min-height: 200px;
  }
  .loader {
    width: 128px;
    margin: auto;
    height: 128px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
