<template>
  <div v-if="videoSrc" id="CamView">
    <div class="cam-view-wrap mb-1">
      <div class="cam-view-container">
        <CamPlayer class="cam-view-cam" :source="videoSrc" :show-timer="true" :poster="getCamPoster()"
                   :is-autoplay="autoPlay" controls ref="video" />
        <div v-if="showTimer" class="cam-time">
          <Timer :value="timer" />
        </div>
      </div>
      <button class="cam-view__btn cam-view__btn_prev" @click="onCamMove('prev')">
        <i class="mdi mdi-chevron-left cam-view__icon"></i>
      </button>
      <button class="cam-view__btn cam-view__btn_next" @click="onCamMove('next')">
        <i class="mdi mdi-chevron-right cam-view__icon"></i>
      </button>
      <button class="cam-view__btn cam-view__btn_frame mr-2" type="button" @click="onCaptureFrame">
        <i class="mdi mdi-camera cam-view__icon"></i>
      </button>
    </div>
    <FileFrameName v-if="fileFrameName" @close="onCloseSaveCaptureFrame" @save="onSaveCaptureFrame" />
    <div class="cam-view-footer row">
      <div class="col-12">
        <div class="box">
          <div class="box-header clearfix">
            <div class="add-new float-end">
              <div class="btn btn-info" @click="goToArchive()">Архив</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CamPlayer from "@/components/CamPlayer.vue";
import Timer from "@/components/Timer.vue";
import moment from "moment";
import FileFrameName from '@/components/FileFrameName.vue'
import { isIOS } from '@/helpers/utils';
import { useDefaultStore } from '@/store';
export default {
  name: "CamView",

  components: {
    CamPlayer,
    Timer,
    FileFrameName
  },

  props: {
  },

  data() {
    return {
      videoSrc: null,
      currentDivision: null,
      timer: moment(new Date()),
      fileFrameName: false,
      frameUrl: null
    };
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    autoPlay() {
      return !isIOS();
    },

    division() {
      return this.$route.params.division ? this.$route.params.division : null;
    },

    camId() {
      return this.$route.params.cam;
    },
    showTimer() {
      return this.store.showTimer;
    }
  },

  created() {
    this.getVideo();
    if (this.store.currentDivision) this.store.setPageTitle(
      "Камера: " + this.store.currentDivision.name + " / " + this.camId
    );
  },

  methods: {

    async getVideo() {
      // let src = this.store.currentDivision
      //   ? `http://${this.store.currentDivision.address}/${this.division}/${this.camId}/f/index.m3u8`
      //   : undefined;
      if (window.location.protocol == "http:") {
        this.videoSrc = this.store.currentDivision
          ? `//${this.store.currentDivision.address}/${this.store.currentDivision.guid}/${this.camId}/f/index.m3u8`
          : undefined;
        return;
      }
      this.videoSrc = this.store.currentDivision
        ? `//${this.store.currentDivision.url}/${this.store.currentDivision.guid}/${this.camId}/f/index.m3u8`
        : undefined;
    },

    getCamPoster() {
      return this.store.currentDivision
        ? `//${this.store.currentDivision.url}/api/StaticPreview/${this.camId}`
        : undefined;
    },

    goToArchive() {
      this.$router.push({
        name: "cam-archive",
        params: {
          division: this.division,
          cam: this.camId
        }
      });
    },

    onCamMove(type) {
      const camList = this.store.currentDivision.camList;
      let cam = this.$route.params.cam;
      const camListLength = camList.length;
      console.log(camList)
      const currentIndex = camList.findIndex(c => c === cam);
      console.log(currentIndex)
      let index = 0;

      if (type === "prev") {
        index = currentIndex > 0 ? currentIndex - 1 : camListLength - 1;
      } else {
        index = currentIndex < camListLength - 1 ? currentIndex + 1 : 0;
      }

      cam = camList[index];
      this.routeToCam(cam);
    },

    routeToCam(cam) {
      this.$router.push({
        name: "cam",
        params: { cam, division: this.division }
      });
    },

    onCaptureFrame() {
      const video = this.$refs.video.$refs["video"]
      const canvas = document.createElement("canvas");

      const width = canvas.width = video.offsetWidth
      const height = canvas.height = video.offsetHeight

      const ctx_draw = canvas.getContext('2d');
      ctx_draw.drawImage(video, 0, 0, width, height);

      this.frameUrl = canvas.toDataURL()

      this.fileFrameName = true
    },

    onSaveCaptureFrame(fileName) {
      const frameUrl = this.frameUrl
      const link = document.createElement("a");
      link.setAttribute("href", frameUrl);
      link.setAttribute("download", `${fileName}.jpg`);
      link.click();
      this.fileFrameName = false
    },

    onCloseSaveCaptureFrame() {
      this.fileFrameName = false
    }
  }
};
</script>

<style lang="stylus" scoped>

#CamView {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px)
  padding: 10px
  overflow-y: auto
  overflow-x: hidden
}

.cam-view-wrap {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center

  .cam-view-container {
    position: absolute;
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden;

    .cam-time {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      position: absolute;
      right: 5px;
      top: 0;
      padding: 5px 15px 10px 15px;
      font-size: 1em;
      color: #fff;
      background: rgba(0, 0, 0, 0.62);
     /* text-shadow:
        0 1px 0 #999,
        0 2px 0 #888,
        0 3px 0 #777,
        0 4px 0 #666,
        0 5px 0 #555,
        0 6px 0 #444,
        0 7px 0 #333,
        0 8px 7px #001135;*/
  }

    .cam-view-cam {
      width: 100%;
      height: 100%;
    }
  }

  .cam-view__btn {
    position: absolute;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    padding: 0;

    &.cam-view__btn_prev {
      left: 0;
    }

    &.cam-view__btn_next {
      right: 0;
    }

    &.cam-view__btn_frame {
      right: 0px;
      top: 30px;
    }

    .cam-view__icon {
      color: #383e47;
      opacity: 0.35;
      font-size: 50px;
      display: block;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
