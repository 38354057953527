import { ApiSys } from './api';
import { useDefaultStore } from "@/store";
import { router } from '@/router';
import MD5 from 'md5';
import { passGen } from "../helpers/utils";

export default {

  async logout() {
    localStorage.token = '';
    const store = useDefaultStore();
    store.setCurrentUser(null);
    // await router.replace({ name: 'login' }).catch(err => err);
    await ApiSys.Logout();
    await router.push({ name: 'login' }).catch(err => err);
    // window.location = '/#/login';
    // location.replace('/#/login');
  },

  async getCurrentUser() {
    const store = useDefaultStore();
    let currentUser = store.currentUser;

    if (currentUser) {
      return currentUser;
    }

    let response = await ApiSys.getCurrentUser();
    if (response.error) {
      this.logout();
    } else {
      store.setCurrentUser(response.data);

      return response.data;
    }
  },

  async checkUser() {
    const store = useDefaultStore();
    let response = await ApiSys.CheckUser();
    if (response.error) {
      this.logout();
    } else {
      store.setAdminUser(response.data);
    }
  },

  async checkAdmin() {
    const store = useDefaultStore();
    await this.checkUser();
    if (!store.isAdmin) {
      this.logout();
    }
  },

  async getUsers() {
    let response = await ApiSys.getUsers();
    if (response.error) {
      // notify.error('Ошибка ', ctx);

      return [];
    } else {
      return response.data;
    }
  },

  async get(user) {

    if (!user.user) {
      return this.new();
    } else {
      let response = await ApiSys.getUser({ user: user.user });

      if (response.error) {
        // notify.error('Ошибка ', ctx);

        return [];
      } else {
        return this.prepareUser(response.data);
      }
    }
  },

  prepareUser(user) {

    if (!user.address) {
      user.address = {
        'city': null,
        'street': null,
        'houseNum': null,
        'apartmentNum': null
      };
    }

    return user;
  },

  new() {
    return {
      'user': null,
      'password': passGen(),
      'email': null,
      'divisions': [],
      'address': {
        'city': null,
        'street': null,
        'houseNum': null,
        'apartmentNum': null
      },
      'role': 0,
      'sessionLimit': false,
      'group': null
    };
  },

  async save(user, allDivisions = null) {

    let result;

    if (user.new) {
      delete user['availableDivisions'];
      delete user['token'];

      // user.password = MD5(user.password);

      // user.divisions = this.prepareSaveDivisions(allDivisions);

      result = await ApiSys.registrationUser({ ...user, password: MD5(user.password) });
    } else {
      if (user.password) {
        await this.changePassword(user);
      }
      delete user['availableDivisions'];
      delete user['token'];
      delete user['password'];

      if (allDivisions) {
        user.divisions = this.prepareSaveDivisions(allDivisions);
      }

      result = await ApiSys.updateUser(user);
    }

    if (result.error) {
      console.log('error save user');
    }

    return result;
  },

  changePassword(user) {
    ApiSys.changePassUser({
      newPassword: MD5(user.password),
      // newPassword: user.password,
      user: user.user
    });
  },

  prepareViewDivisions(divisionsRaw, user) {
    const divisions = divisionsRaw.map(d => {
      return {
        guid: d.guid,
        name: d.name,
        camList: d.camList,
        selected: false
      }
    });

    divisions.forEach((d) => {
      d.selected = false;

      let camList = [];

      d.camList.forEach((c) => {

        let cam = {
          name: c,
          selected: false
        };
        camList.push(cam);
      });
      d.camList = camList;
    });

    user.divisions.forEach(userDivision => {
      let findDivision = divisions.findIndex(d => d['guid'] === userDivision['divisionUuid']);

      if (findDivision !== -1) {
        userDivision.camList.forEach(cam => {
          let findCam = divisions[findDivision].camList.find(c => c['name'] === cam);
          if (findCam) {
            findCam.selected = true;
          }
        });
      }
    });


    divisions.sort(function (a, b) {
      return b.camList.length - a.camList.length;
    });


    return divisions;
  },

  prepareSaveDivisions(allDivisions) {
    let userDivisionsResult = [];

    allDivisions.forEach(d => {
      let division = {
        divisionUuid: d['guid'],
        camList: []
      };

      d.camList.forEach(c => {
        if (c.selected) {
          division.camList.push(c.name);
        }
      });

      if (division.camList.length > 0) {
        userDivisionsResult.push(division);
      }
    });

    return userDivisionsResult;
  }
};
