<template>
  <transition name="slide-fade">
    <div v-if="show" class="main-loader">
      <div>
        <span class="mdi mdi-rotate-right mdi-spin"></span>
      </div>
    </div>
  </transition>
</template>

<script lang="js">
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="stylus">

.main-loader {
  display: flex
  justify-content: center
  align-items: center
  z-index: 9999

  .mdi {
    font-size: 70px
    color: c-accent-text
  }
}


.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: scale(2)
}


</style>
