<template>
  <div v-if="store.currentDivision" id="CamList" :class="[
    { 'has-pagination': isPagination && !smallScreen },
    { 'has-pagination_fullscreen': fullScreen }
  ]">
    <draggable v-model="currentPageCams" item-key="id" class="cams-grid" :class="`cam-grid-layout-${gridCam}`" id="grid"
               v-bind="dragOptions" @start="drag = true" @end="onDragEnd"
               v-if="store.currentDivision && camPageShow && !smallScreen">
      <template #item="{ element }">
        <a class="cam-cell" href="#" @click.prevent>
          <div class="cam-container">
            <CamPlayer class="cam" :show-timer="false" :is-autoplay="autoPlay" :source="getCamSourceUrl(element)"
                       @click.prevent="routeToCam(element)" />
            <div v-if="showCamName" class="cam-name">
              {{ element }}
              <div class="cam-favorite" @click="handlerFavorite(element)">
                <span class="mdi mdi-star" :style="{ 'color': isInFavorite(element) ? 'red' : '#fff' }"></span>
              </div>
            </div>
          </div>
        </a>
      </template>
    </draggable>

    <div v-if="store.currentDivision && camPageShow && smallScreen" class="cams-grid small-grid" @scroll="onScroll">
      <div class="cam-wrapper">
        <div v-for="(cam, index) of currentPageCamsSorted" :key="index" class="cam-cell">
          <div class="cam-container">
            <CamPlayer class="cam" :show-timer="false" :is-autoplay="false" :source="getCamSourceUrl(cam)" controls />
          </div>
          <div class="cam-name">
            <span @click.prevent="routeToCam(cam)">{{ cam }}</span>
            <div class="cam-favorite" @click.prevent="handlerFavorite(cam)">
              <span class="mdi mdi-star" :style="{ 'color': isInFavorite(cam) ? 'red' : '#fff' }"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Pagination :currentPage="page" :totalPages="pagesNum" @update:currentPage="updatePage" v-if="isPagination" />
  </div>
</template>

<script>

//TODO rewrite component
import CamPlayer from "@/components/CamPlayer.vue";
import Pagination from "@/components/Pagination.vue";
import Timer from "@/components/Timer.vue";
import draggable from 'vuedraggable'
import { ApiSys } from '../../services/api';
import moment from "moment";
import { Favorites } from "../../services/favorites";
import { isIOS } from '@/helpers/utils';
import { useDefaultStore } from '@/store';
export default {
  name: "CamList",

  components: {
    CamPlayer,
    Pagination,
    Timer,
    draggable,
  },
  props: {
  },
  data() {
    return {
      camPageShow: false,
      isPagination: true,
      currentDivision: null,
      scrollCamList: [],
      loadedCam: 0,
      drag: false,
      timer: moment(new Date()),

      cameras: null,

      page: 1,
    };
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  async created() {

  },
  computed: {
    autoPlay() {
      return !isIOS();
    },

    inFavoriteList() {
      return this.store.favoriteList;
    },

    list: {
      get: function () {
        const cameras = this.store.currentDivision ? this.store.currentDivision.camList : [];
        // let sort = getSettingValue(`interface.objectCamSort.${this.store.currentDivision.name}`);
        //
        // if (sort) {
        //   sort.split(',').forEach(e => {
        //     cameras.sort((a) => {
        //       return a.name === e ? 1 : -1
        //     })
        //   })
        // }
        this.cameras = cameras;
        return this.cameras;
      },
      set: function (value) {
        this.cameras = value
      }
    },

    smallScreen() {
      return this.store.smallScreen;
    },
    showTimer() {
      return this.store.showTimer;
    },
    showCamName() {
      return this.store.showCamName;
    },

    gridCam() {
      return this.store.gridCam;
    },

    gridSize() {
      return this.gridCam ** 2;
    },

    isLoader() {
      return this.store.loader;
    },

    fullScreen() {
      return this.store.fullScreen;
    },

    division() {
      return this.$route.params.division;
    },

    dragOptions() {
      return {
        animation: 0,
        disabled: false,
        ghostClass: "ghost"
      };
    },

    currentCamNames() {
      let currentCamNames = this.store.camNames.filter((item) => item === this.store.currentDivision)
      return currentCamNames
    },

    currentPageCams() {
      const end = this.page * this.gridSize;
      const start = end - this.gridSize;
      return this.list ? this.list.slice(start, end) : [];
    },

    pagesNum() {
      return this.list ? Math.ceil(this.list.length / this.gridSize) : 0;
    },

    currentPageCamsSorted() {
      return this.currentPageCams;
      //   .sort((a, b) => {
      //   const smallA = String(a.name).toLowerCase();
      //   const smallB = String(b.name).toLowerCase();
      //
      //   if (!a.isGroup && b.isGroup) {
      //     return 1;
      //   }
      //   if (smallA < smallB) {
      //     return -1;
      //   }
      //   if ((smallA > smallB)) {
      //     return 1;
      //   }
      //   return 0;
      // });
    }
  },

  watch: {
    gridCam() {
      if (!this.smallScreen) {
        this.page = 1;
      }
    },

    smallScreen() {
      if (this.smallScreen) {
        this.scrollLoad();
      }
    }
  },

  async mounted() {
    await this.getCurrentDivision();

    if (this.smallScreen) {
      this.scrollLoad();
    }
  },

  methods: {
    isInFavorite(cam) {
      return this.inFavoriteList.find(e => e.division === this.store.currentDivision.guid && e.cam === cam)
    },
    updatePage(p) {
      this.page = p;
    },
    onScroll: function (event) {
      // let wrapper = event.target;
      // let list = wrapper.firstElementChild;
      //
      // let scrollTop = wrapper.scrollTop;
      // let wrapperHeight = wrapper.offsetHeight;
      // let listHeight = list.offsetHeight;
      //
      // let diffHeight = listHeight - wrapperHeight;
      //
      // if (diffHeight <= scrollTop && !this.loading) {
      //   this.scrollLoad();
      // }
    },

    scrollLoad() {
      let countOnPage = 5;
      if (!this.store.currentDivision) return;
      if (!this.store.currentDivision.cameras) return;
      if (this.loadedCam >= this.store.currentDivision.cameras.length) {
        return;
      }

      let nextPartCamList = this.store.currentDivision.cameras.slice(
        this.loadedCam,
        this.loadedCam + countOnPage
      );
      this.scrollCamList = this.scrollCamList.concat(nextPartCamList);
      this.loadedCam = this.loadedCam + countOnPage;
    },

    async getCurrentDivision() {
      this.camPageShow = false;

      if (!this.division) {
        this.$router.push({
          name: "cam-list",
          params: {
            division: this.division
          }
        });
      }

      if (this.store.currentDivision) {
        this.store.setPageTitle(
          "Список камер / " + this.store.currentDivision.name
        );

        if (!this.smallScreen) {
          this.page = 1;
        } else {
          this.loadedCam = 0;
          this.scrollCamList = [];
          this.scrollLoad();
        }

        this.$nextTick(() => {
          this.camPageShow = true;
        });
      }
    },

    /**
     * Открывает страницу камеры
     */
    routeToCam(cam) {
      this.$router.push({
        name: "cam-new",
        params: { cam: cam, division: this.store.currentDivision.guid }
      });
    },

    async handlerFavorite(camName) {
      const inFav = this.isInFavorite(camName);

      if (inFav) {
        this.removeFromFavorite(camName);
      } else {
        this.addToFavorite(camName);
      }
    },

    async addToFavorite(cam) {
      try {
        await Favorites.add({
          division: this.store.currentDivision.guid,
          cam
        });
        this.$toast.success('Камера добавлена в избранное');
      } catch (e) {
        console.log(e);
      }
    },

    async removeFromFavorite(cam) {
      try {
        await Favorites.remove({
          division: this.store.currentDivision.guid,
          cam
        });

        this.$toast.success('Камера убрана из избранного');
      } catch (e) {
        //
      }
    },

    /**
     * Возвращает url для источника камеры
     */
    getCamSourceUrl(cam) {
      // NOTE:
      //   /m/index.m3u8 - previev
      //   /f/index.m3u8 - full
      if (window.location.protocol == "http:")
        return this.store.currentDivision
          ? `http://${this.store.currentDivision.address}/${this.store.currentDivision.guid}/${cam}/m/index.m3u8`
          : undefined;
      return this.store.currentDivision
        ? `https://${this.store.currentDivision.guid}.pervaya1.ru/${this.store.currentDivision.guid}/${cam}/m/index.m3u8`
        : undefined;
    },

    async onDragEnd() {
      this.drag = false
      await this.savePreviewCam()
    },

    async savePreviewCam() {
      const options = {
        divisionId: this.store.currentDivision.guid,
        cameraOrder: this.cameras.map((item) => item)
      }

      // setSettingValue(`interface.objectCamSort.${options.address}`, options.cameraOrder.join(','));
      await ApiSys.changeCameraOrder(options)
    },
  }
};
</script>

<style lang="stylus" scoped>

#CamList {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: hidden
  position: relative

  &.has-pagination {
    padding-bottom: 50px;

    &.has-pagination_fullscreen {
      padding-bottom: 0;
      height: 100%;
    }
  }
}

.ghost {
  border-left: 6px solid rgb(0, 183, 255);
  box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
  opacity: 0.7;
}

#CamList .sortable-drag {
  opacity: 0;
}

.cams-grid {
  display: grid
  overflow-x: hidden
  overflow-y: auto
  width: 100%
  height: 100%
  padding: 10px

  &.cam-grid-layout-3 {
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: (1fr) [3];
    grid-template-rows: repeat(3, 1fr);
  }

  &.cam-grid-layout-4 {
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-rows: (1fr) [4];
    grid-template-rows: repeat(4, 1fr);
  }

  &.cam-grid-layout-5 {
    grid-template-columns: repeat(5, 1fr);
    -ms-grid-rows: (1fr) [5];
    grid-template-rows: repeat(5, 1fr);
  }

  &.cam-grid-layout-6 {
    grid-template-columns: repeat(6, 1fr);
    -ms-grid-rows: (1fr) [6];
    grid-template-rows: repeat(6, 1fr);
  }

  .cam-cell {
    position: relative;
    margin: 1px;
    background: #151515;
    display block

    .cam-container {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding-bottom: 30px

      .cam {
        width: 100%;
        height: 100%;
        /*height: -webkit-fill-available;*/
      }
    }

    .cam-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #272c33;
      position: absolute;
      height: 20px;
      bottom: 0;
      width: 100%;
      color: #fff;
      padding: 15px 10px 15px 10px;
      font-size: 14px;
      line-height: 0;
      opacity: 0.8;
      cursor: pointer;
    }

    .cam-favorite {
      cursor: pointer;
      display: inline
      padding 0 6px
      font-size 20px
    }

    .cam-time {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
      position: absolute
      right: 5px
      top: 0
      padding: 5px 15px 10px 15px
      font-size: 1em
      color: #fff
      background: rgba(0, 0, 0, 0.62)
    }
  }
}

@media (max-width: 768px) {
  #CamList {
    height: inherit;
    overflow: auto;
  }

  .cams-grid {
    display: block
    height: auto;
    overflow: auto;

    .cam-cell {
      width: 100%
      display: block
      height: auto
      min-height: 260px
      background: #151515
      margin-bottom: 20px

      .cam-name {
        color: #fff
        font-weight: bold
        height: 40px
        line-height: 40px
        padding 0 20px
        text-decoration: underline
      }

      .cam-favorite {
        font-size 22px
      }

      .cam-container {
        padding-bottom: 40px
      }
    }
  }
}
</style>
