<template>
  <div v-if="item.type == 'user'" class="tree-item" @click="toggle">
    <div class="tree-icon flex-shrink">👤</div>
    <div class="tree-name">{{ item.name }}</div>
    <div v-if="item.address" class="flex-column address">
      <div class="street" v-if="item.address.street">
        <abbr :title="item.address.street">ул.{{ item.address.street }}</abbr>
      </div>
      <div class="flex-row">
        <div class="house" v-if="item.address.houseNum">д.{{ item.address.houseNum }}</div>
        <div class="apartment" v-if="item.address.apartmentNum">
          кв.{{ item.address.apartmentNum }}
        </div>
      </div>
    </div>
    <div class="tree-actions flex-row">
      <div class="tree-action" @click="clickRemoveFromDivision">-🏠</div>
      <div
        class="tree-action"
        @click="selectAsDefaultDivision"
        :style="{ color: item.def ? 'rgb(241,179,33)' : '#d6d6d6' }"
      >
        <i class="mdi mdi-star"></i>
      </div>
      <div class="tree-action" @click="clickEditUser">🖍</div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import UserEdit from '@/components/editor/UserEdit.vue'
import { ApiSys } from '@/services/api'
export default {
  props: ['item', 'toggle', 'isOpen', 'isLoading', 'refetchData', 'parent'],
  setup() {
    const { showModal, hideModal } = inject('modal')
    const updateTree = inject('updateTree')
    return { showModal, hideModal, updateTree }
  },
  methods: {
    async selectAsDefaultDivision() {
      if (this.item.def) {
        let answer = confirm(
          `Площадка "${this.item.name}" больше не основная для этого пользователя?`
        )

        if (answer) {
          this.item.def = false

          await ApiSys.patchUserAdmin(this.item.name, {
            defaultDivision: null
          })
        }
      } else {
        let answer = confirm(
          `Сделать площадку "${this.item.name}" основной для этого пользователя?`
        )

        if (answer) {
          this.item.def = true

          await ApiSys.patchUserAdmin(this.item.name, {
            defaultDivision: this.parent.item.id
          })
        }
      }
    },
    async clickRemoveFromDivision() {
      let answer = confirm(`Точно удалить пользователя "${this.item.name}" из площадки?`)

      if (answer) {
        await ApiSys.patchUserAdmin(this.item.name, {
          divisionsWithCams: [{ divisionUuid: this.parent.item.id, camList: [] }],
          divisionsOp: 'remove'
        })
        await this.updateTree()
      }
    },
    clickEditUser() {
      this.showModal(
        UserEdit,
        { division: this.parent.item, username: this.item.name, hide: this.hideModal },
        {},
        'Редактировать пользователя',
        'xl'
      )
    }
  }
}
</script>

<style scoped>
.tree-item {
  padding: 0px 12px;
  background: #fff;
  border-radius: 10px;
  color: #000;
  margin-bottom: 5px;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 38px;
}
.tree-name {
  max-width: 40vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tree-actions {
  display: flex;
  gap: 5px;
  margin-left: auto;
}

.tree-action {
  flex: 0 0 auto;
  cursor: pointer;
}

.address {
  gap: 10px;
  font-size: 12px;
  margin: auto;
  max-width: 20vw;
  overflow: hidden;
  max-height: 38px;
}

.street,
.house,
.apartment {
  line-height: 13px;
  text-align: center;
}
.street {
  text-overflow: ellipsis;
  /* max-width: 120px; */
  overflow: hidden;
  margin: auto;
  white-space: nowrap;
}
</style>
