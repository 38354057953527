<template>
  <div class="file-frame">
    <input type="text" placeholder="Введите название файла" v-model="fileName" class="file-frame__input">
    <div class="file-frame__group">
      <button class="file-frame__btn file-frame__btn_close" @click="onClose">
        Закрыть
      </button>
      <button class="file-frame__btn file-frame__btn_save" @click="onSave">
        Скачать
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileName: ''
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      let fileName = this.fileName !== '' ? this.fileName : 'frame'
      
      this.$emit('save', fileName);
    }
  }
}
</script>

<style>
.file-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 10px;
  background: #383e47;
  border: 2px solid #666666;
  width: 250px;
  z-index: 99999;
}

.file-frame__group {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.file-frame__input {
  padding: 5px;
  width: 100%;
}

.file-frame__btn {
  padding: 5px 0px;
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
  color: #fff;
}

.file-frame__btn_close {
  background: #fc4b6c !important;
  margin-right: 5px;
}

.file-frame__btn_save {
  background: #389af0 !important;
}
</style>