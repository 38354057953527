import { updateConfig, checkConfig, loadScript, createMetrika, startTracking } from './helpers'

export default function install (app, options = {}) {

    updateConfig(options)

    checkConfig() 

    loadScript(() => { 
        const metrika = createMetrika(app) 
        startTracking(metrika)
    }, options.scriptSrc)
}