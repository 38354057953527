<template>
  <div class="cont">
    <div class="timeline-container" ref="timeline">
      <div class="error" v-if="error">Произошла ошибка при загрузке плейлиста</div>
      <canvas
        ref="timelineCanvas"
        class="timeline-canvas"
        :width="canvasWidth"
        :height="canvasHeight"
        @mousedown="onMouseDown"
        @touchstart="onTouchStart"
        @wheel="onWheel"
      ></canvas>
      <div class="scrubber" v-if="segments.length" ref="scrubber"></div>
      <div class="tt" :style="tooltipStyle">{{ currentSegmentName }}</div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import moment from 'moment'
import { shallowRef, ref } from 'vue'
import { useDefaultStore } from '@/store'
export default {
  name: 'TimelineNew',
  props: {
    camId: String
  },
  emits: ['playlist-loaded', 'seek', 'loading', 'timeline-ready'],
  setup() {
    const store = useDefaultStore()
    const playlistText = shallowRef('')
    const segments = shallowRef([])
    const touchStartDistance = ref(0)
    return {
      playlistText,
      segments,
      touchStartDistance,
      store
    }
  },
  created() {
    this.debouncedDrawCanvas = debounce(this.drawCanvas, 1) // ~60fps
  },
  data() {
    return {
      canvasWidth: 200,
      canvasHeight: 40,
      halfCanvasWidth: '100px',
      halfCanvasHeight: '20px',
      dpi: 1,
      offsetX: 0,
      scale: 1,
      loading: false,
      mouseDown: false,
      segmentsByDate: new Map(),
      dragStartX: 0,
      date: new Date(),
      currentSegmentName: '',
      mergedSegments: [],
      mergeThreshold: 11000, // 5 seconds in milliseconds
      minScale: 1,
      maxScale: 60,
      error: false,
      totalLength: 0,
      allowMouseEvents: false
    }
  },
  watch: {
    loading() {
      // this.$emit('loading', this.loading)
    }
  },
  computed: {
    totalTime() {
      if (!this.segments.length) return 0
      const lastSegment = this.segments[this.segments.length - 1]
      return lastSegment.s + lastSegment.d - this.segments[0].s
    },
    visibleStartTime() {
      if (!this.segments.length) return 0
      return this.segments[0].s + this.offsetX * this.totalTime
    },
    visibleEndTime() {
      if (!this.segments.length) return 0
      return this.visibleStartTime + this.totalTime / this.scale
    },
    videoSrc() {
      const uuid = this.store.currentDivision.guid
      const url = this.store.currentDivision.address
      return `//${url}/${uuid}/archive/alldays/${this.camId}/720/index.m3u8`
    },
    visibleTimeRange() {
      return {
        start: this.visibleStartTime,
        end: this.visibleEndTime
      }
    },
    tooltipStyle() {
      return {
        left: '50%',
        transform: 'translateX(-50%)',
        display: this.currentSegmentName ? 'block' : 'none'
      }
    },
    canvasStyle() {
      if (this.loading) {
        return {
          background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
          animation: '1.5s shine linear infinite'
        }
      }
      return {}
    }
  },
  async mounted() {
    this.dpi = window.devicePixelRatio
    window.timeline = this
    this.handleResize()
    this.setupResizeObserver()
    await this.getSegmentsData()
  },
  methods: {
    handleResize() {
      if (!this.$refs.timeline) return
      const { width } = this.$refs.timeline.getBoundingClientRect()
      this.canvasWidth = width * this.dpi
      this.halfCanvasWidth = `${width}px`

      const canvas = this.$refs.timelineCanvas
      canvas.getContext('2d').scale(this.dpi, this.dpi)
      this.debouncedDrawCanvas()
      this.updateCurrentSegmentName()
    },
    setupResizeObserver() {
      this.resizeObserver = new ResizeObserver(
        debounce(() => {
          this.handleResize()
        }, 250)
      )
      this.resizeObserver.observe(this.$refs.timeline)
    },
    async getSegmentsData() {
      const url = this.store.currentDivision.address
      const request = await fetch(`//${url}/api/segmentData/${this.camId}`)
      const data = await request.json()
      this.segmentsByDate = data
      let segments = []
      for (let value of Object.values(data)) {
        value = value.sort((a, b) => a.s - b.s)
        segments = segments.concat(value)
      }
      this.segments = segments
      this.mergeSegments()
      this.setLastDayVisible(false)
      const time = this.calculateSeekTime()
      this.$emit('timeline-ready', Object.keys(data), time)
      this.loading = false
    },
    drawCanvas() {
      if (!this.mergedSegments.length) return
      const canvas = this.$refs.timelineCanvas
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw background
      ctx.fillStyle = '#b5b6b9'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw visible merged segments
      ctx.fillStyle = '#6a6d72'
      let batchStart = null
      let batchEnd = null
      const finishBatch = () => {
        if (batchStart !== null && batchEnd !== null) {
          const startX = Math.floor(this.getXPositionForTime(batchStart))
          const endX = Math.ceil(this.getXPositionForTime(batchEnd))
          ctx.fillRect(startX, 0, endX - startX, canvas.height)
        }
      }

      this.mergedSegments.forEach((segment) => {
        const segmentStart = segment.s
        const segmentEnd = segment.s + segment.d

        // Check if the segment is within the visible time range
        if (segmentEnd > this.visibleStartTime && segmentStart < this.visibleEndTime) {
          if (batchStart === null) {
            batchStart = segmentStart
            batchEnd = segmentEnd
          } else if (segmentStart - batchEnd < 1000) {
            // 1 second threshold for batching
            batchEnd = segmentEnd
          } else {
            finishBatch()
            batchStart = segmentStart
            batchEnd = segmentEnd
          }
        }
      })

      finishBatch() // Draw the last batch
    },
    getXPositionForTime(time) {
      const timeRange = this.visibleEndTime - this.visibleStartTime
      const a = time - this.visibleStartTime
      const b = a / timeRange
      return b * this.canvasWidth
    },
    parseDate(dateString) {
      // Extract year, month, day, hour, minute, second
      const year = parseInt(dateString.slice(0, 4), 10)
      const month = parseInt(dateString.slice(4, 6), 10) - 1 // Month is zero-indexed in JavaScript Date
      const day = parseInt(dateString.slice(6, 8), 10)
      const hour = parseInt(dateString.slice(8, 10), 10)
      const minute = parseInt(dateString.slice(10, 12), 10)
      const second = parseInt(dateString.slice(12, 14), 10)

      // Return the Date object
      return new Date(year, month, day, hour, minute, second)
    },
    mergeSegments() {
      if (this.segments.length === 0) {
        this.mergedSegments = []
        return
      }

      const merged = []
      let current = { ...this.segments[0] }

      for (let i = 1; i < this.segments.length; i++) {
        const segment = this.segments[i]
        if (segment.s - (current.s + current.d) <= this.mergeThreshold) {
          // Merge segments
          current.d = segment.s + segment.d - current.s
        } else {
          // Add current segment and start a new one
          merged.push(current)
          current = { ...segment }
        }
      }

      // Add the last segment
      merged.push(current)

      this.mergedSegments = merged
    },
    setLastDayVisible(emitEvent = true) {
      const segmentsByDay = Object.entries(this.segmentsByDate)
      if (segmentsByDay.length === 0) return

      // eslint-disable-next-line no-unused-vars
      const [_, lastDaySegments] = segmentsByDay[segmentsByDay.length - 1]

      if (lastDaySegments.length === 0) return

      const firstSegmentOfDay = lastDaySegments[0]
      const lastSegmentOfDay = lastDaySegments[lastDaySegments.length - 1]

      const dayStart = firstSegmentOfDay.s
      const dayEnd = lastSegmentOfDay.s + lastSegmentOfDay.d

      this.scale = this.totalTime / (dayEnd - dayStart)

      const time = new Date(dayStart)

      this.setTimelinePosition(time, emitEvent)
    },

    groupSegmentsByDay() {
      const days = []
      let currentDay = null

      for (const segment of this.segments) {
        const segmentDay = moment(segment.s).startOf('day').valueOf()

        if (currentDay === null || segmentDay !== currentDay.date) {
          if (currentDay) {
            currentDay.end =
              currentDay.segments[currentDay.segments.length - 1].s +
              currentDay.segments[currentDay.segments.length - 1].d
            days.push(currentDay)
          }
          currentDay = {
            date: segmentDay,
            start: segment.s,
            segments: []
          }
        }

        currentDay.segments.push(segment)
      }

      if (currentDay) {
        currentDay.end =
          currentDay.segments[currentDay.segments.length - 1].s +
          currentDay.segments[currentDay.segments.length - 1].d
        days.push(currentDay)
      }

      return days
    },
    onMouseDown(e) {
      this.mouseDown = true
      this.dragStartX = e.clientX
      document.addEventListener('mousemove', this.onMouseDrag)
      document.addEventListener('mouseup', this.onMouseUp)
    },

    onMouseDrag(e) {
      if (!this.mouseDown || this.loading) return
      const dx = e.clientX - this.dragStartX

      const visibleDuration = this.visibleEndTime - this.visibleStartTime
      const timeDiff = (dx / this.canvasWidth) * visibleDuration

      // Calculate new offsetX
      const newOffsetX = this.offsetX - timeDiff / this.totalTime
      this.offsetX = newOffsetX
      this.dragStartX = e.clientX
      this.updateCurrentSegmentName()
      this.debouncedDrawCanvas()
    },

    onMouseUp() {
      if (this.mouseDown && !this.loading) {
        this.updateCurrentSegmentName()
        this.debouncedDrawCanvas()

        const visibleDuration = this.visibleEndTime - this.visibleStartTime
        const centerTime = this.visibleStartTime + visibleDuration / 2

        // Find the first segment that starts after the current scrubber position
        const nextSegment = this.segments.find((segment) => segment.s > centerTime)

        if (nextSegment) {
          // Move the timeline to the start of the next segment
          this.setTimelinePosition(new Date(nextSegment.s), false)
        } else {
          // If no segment is found to the right, stay at the current position
          this.setTimelinePosition(new Date(centerTime), false)
        }
        // Calculate and emit the seek time
        const seekTime = this.calculateSeekTime()
        this.$emit('seek', seekTime)
      }

      this.mouseDown = false
      document.removeEventListener('mousemove', this.onMouseDrag)
      document.removeEventListener('mouseup', this.onMouseUp)
    },

    calculateSeekTime() {
      const visibleDuration = this.visibleEndTime - this.visibleStartTime
      const centerTime = this.visibleStartTime + visibleDuration / 2
      const centerDate = new Date(centerTime)
      const centerDateStr = moment(centerDate).format('YYYY_MM_D')
      const segmentsForDay = this.segmentsByDate[centerDateStr]
      let duration = 0
      for (let segment of segmentsForDay) {
        if (segment.s < centerTime) {
          duration += segment.d
        } else {
          break
        }
      }
      return {
        d: moment(centerDate).format('YYYY_MM_D'),
        time: duration / 1000
      }
    },
    seekToNextDay() {
      const visibleDuration = this.visibleEndTime - this.visibleStartTime
      const centerTime = this.visibleStartTime + visibleDuration / 2
      const currentDay = new Date(centerTime)
      const nextDay = moment(currentDay).add(1, 'day').format('YYYY_MM_D')
      const nextDaySegments = this.segmentsByDate[nextDay]
      if (nextDaySegments) this.setTimelinePosition(new Date(nextDaySegments[0].s))
    },
    updateCurrentSegmentName() {
      if (!this.segments.length) return
      const visibleDuration = this.visibleEndTime - this.visibleStartTime
      const centerTime = this.visibleStartTime + visibleDuration / 2
      this.currentSegmentName = moment(centerTime).format('YYYY-MM-DD HH:mm:ss')
    },

    setTimelinePosition(dateTime, emitEvent = true) {
      const targetTime = moment(dateTime).valueOf()
      // Find the closest segment to the target time
      const closestSegment = this.findClosestSegment(targetTime)

      if (!closestSegment) {
        console.warn('No segments available')
        return
      }

      // Calculate the time to center on (either target time or start of closest segment)
      const centerTime =
        targetTime >= closestSegment.s && targetTime < closestSegment.s + closestSegment.d
          ? targetTime
          : closestSegment.s

      // Calculate the offset to center the target time
      const timeFromStart = centerTime - this.segments[0].s
      this.offsetX = timeFromStart / this.totalTime - 0.5 / this.scale
      // Ensure offsetX is within valid range (0 to 1)
      //this.offsetX = Math.max(0, Math.min(1, this.offsetX))

      // Update the view
      this.updateCurrentSegmentName()
      this.drawCanvas()

      if (emitEvent) {
        // Emit seek event
        const seekTime = this.calculateSeekTime()
        this.$emit('seek', seekTime)
      }
    },

    findClosestSegment(time) {
      return this.segments.reduce((closest, segment) => {
        const currentDiff = Math.abs(time - (segment.s + segment.d / 2))
        const closestDiff = Math.abs(time - (closest.s + closest.d / 2))
        return currentDiff < closestDiff ? segment : closest
      })
    },

    onProgress(time) {
      if (!this.segments.length || this.mouseDown) return
      const startTime = this.segments[0].s
      const addedTime = startTime + time

      const currentTime = this.visibleStartTime + (this.visibleEndTime - this.visibleStartTime) / 2
      let newTime = currentTime + 1000 // Add one second (1000 ms)
      // Find the segment that contains the new time
      let targetSegment = this.segments.find(
        (segment) => newTime >= segment.s && newTime < segment.s + segment.d
      )
      //console.log('onProgress', newTime, targetSegment)
      // If no segment found, find the next available segment
      if (!targetSegment) {
        targetSegment = this.segments.find((segment) => segment.s > newTime)
        if (targetSegment) {
          newTime = targetSegment.s
        } else {
          // If we've reached the end, loop back to the start
          newTime = this.segments[0].s
        }
      }
      const totalTime = this.totalTime
      this.offsetX = (addedTime - this.segments[0].s) / totalTime
      this.offsetX = Math.max(0, Math.min(1, this.offsetX))

      // Update scrubber position without emitting seek event
      this.updateCurrentSegmentName()
      this.drawCanvas()
    },
    onWheel(e) {
      e.preventDefault()
      const scaleFactor = e.deltaY > 0 ? 1.1 : 0.9
      this.updateScale(scaleFactor)
    },

    onTouchStart(e) {
      this.allowMouseEvents = false
      if (e.touches.length === 1) {
        this.touchDrag = true
        this.dragStartX = e.touches[0].clientX
        window.addEventListener('touchend', this.onTouchEnd)
        window.addEventListener('touchmove', this.onTouchMove)
      }
      if (e.touches.length === 2) {
        this.touchStartDistance = this.getTouchDistance(e.touches)
      }
    },

    onTouchMove(e) {
      if (e.touches.length === 1 && !this.loading && this.touchDrag) {
        const event = e.touches[0]
        const dx = event.clientX - this.dragStartX
        const visibleDuration = this.visibleEndTime - this.visibleStartTime
        const timeDiff = (dx / this.canvasWidth) * visibleDuration

        // Calculate new offsetX
        const newOffsetX = this.offsetX - timeDiff / this.totalTime

        this.offsetX = newOffsetX
        this.dragStartX = event.clientX
        this.updateCurrentSegmentName()
        this.debouncedDrawCanvas()
      }
      if (e.touches.length === 2) {
        const currentDistance = this.getTouchDistance(e.touches)
        this.touchStartDistance = currentDistance
      }
    },

    onTouchEnd() {
      window.removeEventListener('touchend', this.onTouchEnd)
      this.touchDrag = false
      // Calculate and emit the seek time
      const seekTime = this.calculateSeekTime()
      this.$emit('seek', seekTime)
      this.touchStartDistance = 0
    },

    getTouchDistance(touches) {
      const dx = touches[0].clientX - touches[1].clientX
      const dy = touches[0].clientY - touches[1].clientY
      return Math.sqrt(dx * dx + dy * dy)
    },

    updateScale(scaleFactor) {
      const oldScale = this.scale
      const newScale = Math.max(this.minScale, Math.min(this.maxScale, oldScale * scaleFactor))
      const startTime = this.segments[0].s
      const currentTime =
        startTime + this.offsetX * this.totalTime + this.totalTime / this.scale / 2
      this.scale = newScale
      this.setTimelinePosition(new Date(currentTime), false)
      this.debouncedDrawCanvas()
    }
  }
}
</script>

<style scoped>
.timeline-container {
  position: relative;
  width: 100%;
}
.timeline-canvas {
  width: v-bind(halfCanvasWidth);
  height: v-bind(halfCanvasHeight);
  touch-action: none;
  background-size: 200% 100%;
}
.scrubber {
  position: absolute;
  top: -3px;
  left: 50%;
  width: 2px;
  height: 120%;
  background-color: #fff;
  pointer-events: none;
}

.scrubber::after,
.scrubber::before {
  content: '';
  position: absolute;
  left: -2px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.scrubber::before {
  top: 0;
  border-top: 5px solid #fff;
}

.scrubber::after {
  bottom: 0;
  border-bottom: 5px solid #fff;
}

.tt {
  position: absolute;
  top: -25px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
}

.params {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cont {
  width: 100%;
}

.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  white-space: nowrap;
}
canvas::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 5s infinite;
  content: '';
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
