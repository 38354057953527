<template>
  <ul v-if="nodes">
    <TreeItem
      v-for="node of nodes"
      :key="node.key"
      :item="node"
      ref="rootNode"
      :lazy="lazy"
      :components="$slots"
      :refetch="refetch"
      :parent="this"
    />
  </ul>
</template>

<script>
import TreeItem from './TreeItem.vue'
import { provide } from 'vue'
import { generateKeys, isNodeLeaf } from './utils';
export default {
  name: 'TreeView',
  components: {
    TreeItem
  },
  props: {
    root: {
      type: null,
      default: null
    },
    expandedKeys: {
      type: Object,
      default: () => {}
    },
    lazy: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refetch: {
      type: Object,
      default: () => {
        return {}
      }
    },
    filterValue: {
      type: String,
      default: ''
    },
    filterMode: {
      type: String,
      default: 'lenient'
    }
  },
  data() {
    return {
      value: generateKeys(this.root),
      d_expandedKeys: this.expandedKeys || {}, // always open root
    }
  },
  watch: {
    
  },
  computed: {
    filteredValue() {
      let filteredNodes = []
      const filterText = this.filterValue.trim().toLowerCase()
      const strict = this.filterMode === 'strict'

      for (let node of this.value) {
        let _node = { ...node }
        let paramsWithoutNode = { filterText, strict }

        if (
          (strict &&
            (this.findFilteredNodes(_node, paramsWithoutNode) ||
              this.isFilterMatched(_node, paramsWithoutNode))) ||
          (!strict &&
            (this.isFilterMatched(_node, paramsWithoutNode) ||
              this.findFilteredNodes(_node, paramsWithoutNode)))
        ) {
          filteredNodes.push(_node)
        }
      }
      return filteredNodes
    },
    nodes() {
      if (this.filterValue && this.filterValue.trim().length > 0) {
        return this.filteredValue
      } else {
        return this.value
      }
    }
  },
  async created() {
    provide('treeKeys', this.d_expandedKeys)
  },
  async mounted() {
    await this.$refs["rootNode"][0].open();
  },
  methods: {
    isFilterMatched(node, { filterText, strict }) {
      let matched = node.name.indexOf(filterText) > -1
      if (!matched || (strict && !isNodeLeaf(node))) {
        matched = this.findFilteredNodes(node, { filterText, strict }) || matched
      }
      return matched
    },
    findFilteredNodes(node, paramsWithoutNode) {
      if (node) {
        let matched = false

        if (node.children) {
          let childNodes = [...node.children]

          node.children = []

          for (let childNode of childNodes) {
            let copyChildNode = { ...childNode }

            if (this.isFilterMatched(copyChildNode, paramsWithoutNode)) {
              matched = true
              node.children.push(copyChildNode)
            }
          }
        }

        if (matched) {
          return true
        }
      }
      return false
    }
  }
}
</script>
<style scoped>
ul {
  padding: 0;
}
</style>