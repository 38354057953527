<template>
    <div v-if="!loading" class="box">
        <div class="box-header">
            <CFormInput v-model="divisionFilter" placeholder="Поиск" autocomplete="off" />
        </div>
    </div>
    <div v-if="loading" class="row">
        <div class="loader"></div>
    </div>
    <div v-if="!loading" class="row">
        <div v-for="(division, idx) of paginatedFilteredList" :key="idx" class="col-sm-12 col-md-4 col-xl-4">
            <div class="box box-danger">
                <div class="box-header d-flex justify-content-between align-content-center">
                    <h3 class="box-title" style="font-size: 12px; word-break: break-all;">{{ division.name }}</h3>
                </div>
                <div class="box-footer d-grid">
                    <button type="button" class="btn btn-block btn-default btn-sm" @click="putDivisionToGroup(division)">
                        Выбрать
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <Pagination v-if="!loading" :currentPage="page" :totalPages="Math.ceil(filteredDivisionList.length / 12)"
                    @update:currentPage="(p) => { page = p }" :maxVisiblePages="5" />
    </div>
</template>

<script>
import { ApiSys } from '@/services/api';
import { CFormInput } from '@coreui/bootstrap-vue';
import Pagination from '@/components/Pagination.vue';
import { useDefaultStore } from '@/store';

export default {
    name: "AddDivisionsToGroup",
    components: { CFormInput, Pagination },
    emits: ['updateTree'],
    props: {
        group: Object,
        hide: Function
    },
    data() {
        return {
            divisionFilter: '',
            allDivisions: [],
            page: 1,
            loading: true
        }
    },
    setup() {
        const store = useDefaultStore();
        return {
            store
        }
    },
    async created() {
        this.loading = true;
        this.allDivisions = await ApiSys.getAdminDivisions();
        this.loading = false;
    },
    watch: {
        divisionFilter() {
            this.page = 1;
        }
    },
    computed: {
        filteredDivisionList() {
            return this.allDivisions.filter(division => {
                if (this.divisionFilter.length < 2) {
                    return this.allDivisions;
                } else {
                    return division.name.toLowerCase().includes(this.divisionFilter.toLowerCase());
                }
            });
        },
        paginatedFilteredList() {
            return this.filteredDivisionList
                .slice((this.page - 1) * 12, this.page * 12)
        }
    },
    methods: {
        async putDivisionToGroup(division) {
            let answer = confirm(`Перенести площадку "${division.name}" в группу "${this.group.name}" ?`);

            if (answer) {
                const result = await ApiSys.setDivisionParent(this.group.id, division.guid);
                if (result.error) {
                    this.$toast.error(result.error.message);
                } else {
                    this.$emit('updateTree');
                    this.$toast.success('Площадка успешно добавлена в группу');
                    this.hide();
                }
            }
        }
    }
}
</script>
<style scoped>
.loader {
    width: 64px;
    margin: auto;
    height: 64px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>