<template>
  <CModal :visible="isOpen" @close="emitClose" backdrop>
    <CModalHeader>
      <CModalTitle>Интервал</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="input-group">
        <TimeInput v-model="rangeStart" class="mx-2" />
        <TimeInput v-model="rangeEnd" />
      </div>

      <ul class="interval-modal__errors" v-if="hasError">
        <li class="interval-modal__error" v-for="error in errors" :key="error">
          {{ error }}
        </li>
      </ul>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="emitClose">
        Закрыть
      </CButton>
      <CButton color="primary" @click="emitInterval">Скачать</CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import TimeInput from '@/components/TimeInput.vue';
import { useDefaultStore } from '@/store';
import { CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CButton } from "@coreui/bootstrap-vue";
const ERROR = {
  START_OUT: `Левая граница меньше минимального времени`,
  END_OUT: `Правая граница больше максимального времени`,
  WRONG_ORDER: `Левая граница больше правой`,
  MAX_INTERVAL: `Максимальный отрезок для загрузки - 2 часа`
};

export default {
  name: 'IntervalModal',
  components: { TimeInput, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CButton },
  emits: ['close', 'ok'],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    maxInterval: {
      type: Array,
      default: () => [null, null]
    },
    range: {
      type: Array,
      default: () => [null, null]
    }
  },
  data() {
    return {
      rangeStart: null,
      rangeEnd: null,
      errors: []
    };
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    hasError() {
      return Boolean(this.errors.length);
    },

    interval() {
      return [this.rangeStart, this.rangeEnd];
    },

    maxIntervalString() {
      return [this.secondsToDateString(this.maxInterval[0]), this.secondsToDateString(this.maxInterval[1])];
    }
  },

  watch: {
    interval() {
      this.checkValidity();
    },
    range() {
      this.rangeStart = this.secondsToDateString(this.range[0]);
      this.rangeEnd = this.secondsToDateString(this.range[1]);
    }
  },

  async mounted() {
    this.rangeStart = this.secondsToDateString(this.range[0]);
    this.rangeEnd = this.secondsToDateString(this.range[1]);
  },

  methods: {
    secondsToDateString(seconds) {
      const date = new Date(seconds * 1000);

      try {
        return date.toISOString().split(/[T.]/)[1];
      } catch (e) {
        return '00:00:00'
      }
    },

    dateStringToSeconds(dateString) {
      const timeParts = dateString.split(/:/);
      let result = 0;

      if (timeParts[0]) {
        result += Number(timeParts[0]) * 3600
      }

      if (timeParts[1]) {
        result += Number(timeParts[1]) * 60
      }

      if (timeParts[2]) {
        result += Number(timeParts[2])
      }

      return result;
    },

    checkValidity() {
      this.errors = [];
      let isValid = true;

      const [start, end] = this.interval;
      const [min, max] = this.maxIntervalString;

      if (start < min) {
        isValid = false;
        this.errors.push(ERROR.START_OUT);
      }

      if (end > max) {
        isValid = false;
        this.errors.push(ERROR.END_OUT);
      }

      if (start >= end) {
        isValid = false;
        this.errors.push(ERROR.WRONG_ORDER);
      }

      if (this.user && this.user.user !== "admin") {
        if (this.dateStringToSeconds(end) - this.dateStringToSeconds(start) > 7200) {
          isValid = false;
          this.errors.push(ERROR.MAX_INTERVAL);
        }
      }
      return isValid;
    },

    emitInterval(event) {
      event.preventDefault();
      this.checkValidity();

      if (!this.hasError) {
        const dateStart = this.dateStringToSeconds(this.rangeStart);
        const dateEnd = this.dateStringToSeconds(this.rangeEnd);

        this.$emit("ok", [dateStart, dateEnd]);
        this.emitClose();
      }

    },
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>

<style>
.interval-modal__errors {
  margin-top: 1em;
  margin-bottom: 0;
  color: #ea4646;
}
</style>
