<template>
  <div v-if="store.currentDivision" class="cam-archive" id="CamArchive">
    <page-header v-if="!fullScreen && allMode" :hideTimerButton="allMode"></page-header>
    <div class="cam-view-wrap mb-2">
      <p v-if="loading">Загрузка...</p>
      <div v-if="allMode && videoShow" class="all-cams-view-container fit-container">
        <div class="fit cams-grid" :class="`cam-grid-layout-${gridCam}`">
          <a v-for="(camData, camIndex) of allCamsSortedByCurrentPage" :key="camIndex" class="cam-list__item"
             @click.prevent="routeToCam(camData.name)" href="#">
            <Loader :show="!videoShow"></Loader>
            <CamPlayer v-if="camData.display" class="cam-list__item-video" :source="camData.src" :showTimer="false"
                       :isAutoplay="autoPlay" :hasPlaybackRates="false" :canZoom="false"
                       @onVideoTime="camData.date = $event" @ended="reloadTrack(camIndex)" ref="player" />
            <div v-if="showTimer && camData.date" class="cam-time">
              <Timer :value="camData.date" />
            </div>
            <div v-if="showCamName" class="cam-name">
              {{ camData.name }}
            </div>
          </a>
        </div>
      </div>
      <Loader :show="!videoShow"></Loader>
      <div v-if="videoSrc && videoShow" class="cam-view-container">
        <CamPlayer v-if="videoSrc && videoShow" class="cam-view-cam cam-archive__player" :source="videoSrc"
                   :showTimer="showTimer" :isAutoplay="autoPlay" :hasPlaybackRates="true" :canZoom="true" controls
                   @onVideoTime="updateTimeAtVideoPreview" @update:current-time="updateTimeFromPlayer"
                   @ended="reloadTrack" @pause="timer = null" ref="player" />
        <div v-if="showTimer && timer" class="cam-time">
          <Timer :value="timer" ref="timerRef" />
        </div>
      </div>

      <template v-if="!allMode">
        <button class="cam-view__btn cam-view__btn_prev" @click="onCamMove('prev')">
          <i class="mdi mdi-chevron-left cam-view__icon"></i>
        </button>
        <button class="cam-view__btn cam-view__btn_next" @click="onCamMove('next')">
          <i class="mdi mdi-chevron-right cam-view__icon"></i>
        </button>
        <button class="cam-view__btn cam-view__btn_frame mr-2" type="button" @click="onCaptureFrame">
          <i class="mdi mdi-camera cam-view__icon"></i>
        </button>
      </template>
    </div>

    <FileFrameName v-if="fileFrameName" @close="onCloseSaveCaptureFrame" @save="onSaveCaptureFrame" />

    <div class="py-2 m-0 mb-2 options" style="background: #272c33;">
      <div v-if="!downloadLink" class="options__item">
        <Calendar v-if="!downloadLoader" @input="(val) => { allMode ? getDataForCamList(val) : getDatForOneCam(val) }"
                  @navigation="changeCalendar" ref="calendar">
        </Calendar>
      </div>

      <div v-if="downloadLink" class="options__item">
        <div class="btn btn-danger" @click="backToArh()">Назад
        </div>
      </div>

      <div class="options__item">
        <div v-if="!downloadLink && !downloadLoader && !allMode" class="btn btn-success" @click="openDownloadModal()">
          Скачать
        </div>
      </div>

      <div class="options__item">
        <div class="flex-row flex-center">
          <Pagination v-if="allCams.length > 0" :currentPage="page" :totalPages="allCams.length"
                      @update:currentPage="updatePage" />
          <div class="flex-shrink add-new float-end">
            <div v-if="!downloadLoader" class="btn btn-info" @click="goToLiveCam()">Онлайн
            </div>
            <div v-if="!progressShow && progressBtnShow" class="btn btn-info btn_show" @click="onShow">Список загрузок
            </div>
            <div v-if="downloadLoader" class="btn btn-danger" @click="canceldownLoad()">Отменить
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="progress-list">
      <List v-if="progressShow" :progress="progress" @hidden="onHidden" />
    </div>

    <div class="row">
      <div class="col-12">
        <div class="box mb-1">
          <div v-if="sequenceLoader || downloadLoader" class="box-header clearfix text-info text-center">
            <i class="mdi mdi-rotate-right mdi-spin" style="font-size: 36px; line-height: 36px"></i>
          </div>

          <CamTimeline v-if="!sequenceLoader && !downloadLoader && !downloadLink" class="box-header clearfix"
                       :cursor="currentTime" :range="range" :max-interval="maxInterval"
                       @update:cursor="updateTimeLineCursor" @update:range="updateRange"
                       @cancel-load="cancelSequenceLoad" />
        </div>
      </div>
    </div>

    <IntervalModal :is-open="isDownloadModalOpen" :max-interval="maxRange" :range="downloadInterval"
                   @close="closeDownloadModal" @ok="downLoad" />
  </div>
</template>

<script>
import CamPlayer from '@/components/CamPlayer.vue';
import PageHeader from '../../components/PageHeader.vue';
import CamTimeline from '@/components/CamTimeline.vue';
import IntervalModal from '@/views/Cams/Archive/IntervalModal.vue';
import FileFrameName from '@/components/FileFrameName.vue'
import List from '@/components/List.vue'
import Pagination from "@/components/Pagination.vue";

import moment from 'moment';
import { ApiSys } from '../../services/api';
import Timer from '@/components/Timer.vue';
import { isIOS } from '@/helpers/utils';
import { keepInInterval } from '@/helpers';
import { getSettingValue } from '../../services/local-settings';
import { useDefaultStore } from '@/store';
import Calendar from "../../components/Calendar.vue";
import Loader from "../../components/Loader.vue";
import * as _ from 'lodash-es';
const SECONDS_IN_DAY = 86400;
const MAX_INTERVAL = [0, SECONDS_IN_DAY - 1];
const LOAD_TIME = 300;

export default {
  name: 'CamArchive',
  components: {
    Calendar,
    CamPlayer,
    CamTimeline,
    Timer,
    IntervalModal,
    FileFrameName,
    List,
    PageHeader,
    Loader,
    Pagination
  },
  props: {
  },
  data() {
    return {
      isDownloadModalOpen: false,
      currentDivision: null,
      currentTime: 0,
      oldCurrentTime: 0,
      range: MAX_INTERVAL,
      downloadInterval: [],
      maxRange: MAX_INTERVAL,
      dateFromCalendar: null, // хранит дату, выбранную во всплывающем окошке
      division: this.$route.params.division ? this.$route.params.division : null,
      camId: this.$route.params.cam ? this.$route.params.cam : null,
      videoSrc: null,
      videoShow: false,
      sequenceLoader: false,
      downloadLoader: false,
      videoGuid: null,
      downloadLink: null,
      timer: null, //moment(new Date()),
      modalShow: false,
      fileFrameName: false,
      frameUrl: null,
      allMode: false,
      progress: [], // downloading files
      progressShow: false, // modal flag
      progressBtnShow: false, // button flag
      intervalProgress: null, // setInterval value
      downloadOldFormat: false, // true if progress's not working

      loading: false,
      allCams: [], // хранит инфу по камерам во время режима allMode - когда архив по всем камерам
      allCamsTmp: [], // хранит инфу до получения данных по всем камерам во время режима allMode
      page: 1,

      startRangeForOneCamMode: 0, // хранит стартовое значение для режима архива для одной камеры
    };
  },
  watch: {
    downloadLoader(value) {
      if (value) {
        this.intervalProgress = setInterval(this.checkProgress, 500);
      } else {
        clearInterval(this.intervalProgress)
      }
    },

    $route() {
      if (this.allMode) {
        this.getCurrentDivision();
      }
    },
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    autoPlay() {
      return !isIOS();
    },
    maxInterval() {
      return MAX_INTERVAL;
    },
    showTimer() {
      return this.store.showTimer;
    },
    showCamName() {
      return this.store.showCamName;
    },
    gridCam() {
      return this.store.gridCam;
    },
    gridSize() {
      return this.gridCam ** 2;
    },
    allCamsSortedByCurrentPage() {
      const end = this.page * this.gridSize;
      const start = end - this.gridSize;

      return this.allCams.slice(start, end);
    },
    loadSequence() {
      return _.debounce(this.allMode ? this.getDataForCamList : this.getDatForOneCam, 1500, {
        leading: false
      });
    },
    rangeStart() {
      return this.range[0];
    },
    rangeModel: {
      get() {
        return this.range;
      },
      set(range) {
        if (!this.allMode) {
          // this.range = range;

          this.downloadInterval = range;

          const [start, end] = range;
          const currentCursor = this.currentTime;
          const cursorCorrection = keepInInterval(start, end, currentCursor);

          if (currentCursor !== cursorCorrection) {
            this.currentTime = cursorCorrection;
          }
        }
      }
    },
    fullScreen() {
      return this.store.fullScreen;
    },
  },
  async beforeMount() {
    if (this.$route.name === 'cam-archive') this.loadSequence(this.dateFromCalendar)
  },
  async created() {
    this.dateFromCalendar = this.store.dateSave || moment().format()
    this.currentTime = Number(localStorage.getItem('cursorPosition')) || 0;
    //this.store.toggleLoader(true);

    if (this.allMode) {
      this.getCurrentDivision();
    }
    //this.store.toggleLoader(false);
  },
  mounted() {
    if (this.store.currentDivision) this.store.setPageTitle(
      "Архив камеры: " + this.store.currentDivision.name + " / " + this.camId
    );
    // this.dateFromCalendar = this.store.dateSave || moment().format()
  },
  methods: {
    sortAllCams() {
      let sort = getSettingValue(`interface.objectCamSort.${this.store.currentDivision.name}`);

      if (sort) {
        sort.split(',').forEach(e => {
          this.allCams.sort((a) => {
            return a.name === e ? 1 : -1
          })
        })
      }
    },
    updatePage(p) {
      this.page = p;
    },
    cancelSequenceLoad() {
      this.loadSequence.cancel();
    },

    updateRange(range) {
      this.rangeModel = range;
      this.loadSequence(this.dateFromCalendar);
    },

    updateTimeLineCursor(time, shouldTriggerPlayer = true) {
      if (this.currentTime === time) {
        return;
      }
      this.oldCurrentTime = this.currentTime;
      if (shouldTriggerPlayer) {
        this.setTimeByMode(time - this.rangeStart);
        if (this.allMode) {
          this.currentTime = time;
          this.getDataForCamList();
        } else {
          this.getDatForOneCam(this.dateFromCalendar);
        }
      }
    },

    setTimeByMode(value) {
      if (!this.$refs.player) return;
      if (this.allMode) {
        this.$refs.player.forEach((e, i) => {
          this.$refs.player[i].setTime(value);
        });
      } else {
        this.$refs.player.setTime(value);
      }
    },

    updateTimeFromPlayer(time) {
      this.updateTimeLineCursor(this.rangeStart + time, false);
    },

    /**
     * Метод обновляет время, отображаемое рядом с миниатюрой трансляции
     */
    updateTimeAtVideoPreview(date) {
      this.timer = date;
    },

    getCurrentDivision() {
      this.camPageShow = false;
      // this.store.currentDivision = this.currentUser.availableDivisions.byGuid[this.division];

      if (this.store.currentDivision) {
        this.store.setPageTitle(
          "Список камер / " + this.store.currentDivision.name + " / архив по всем камерам",
        );

        if (!this.smallScreen) {
          this.page = 1;
        } else {
          this.loadedCam = 0;
          this.scrollCamList = [];
          this.scrollLoad();
        }

        this.$nextTick(() => {
          this.camPageShow = true;
        });
      }
    },

    goToLiveCam() {
      if (this.allMode) {
        this.$router.push({
          name: 'cam-listN',
          params: {
            division: this.division,
          }
        });
      } else {
        this.$router.push({
          name: 'cam',
          params: {
            division: this.division,
            cam: this.camId
          }
        });
      }
    },

    async getDatForOneCam(val) {
      this.dateFromCalendar = val;
      let savedPosition = Number(localStorage.getItem('cursorPosition')) || 0;
      let interval = MAX_INTERVAL;

      this.startRangeForOneCamMode = savedPosition;

      if (savedPosition) {
        interval = [savedPosition, MAX_INTERVAL[1]];
      }

      this.startRangeForOneCamMode = savedPosition + LOAD_TIME;

      await this.getDataSequence(interval, this.camId);
    },

    async getDataForCamList() {
      this.loading = true;
      this.allCams = [];
      // let currentPageCam = this.store.pageCam.filter((item) => item.name === this.store.currentDivision.name);
      // let currentPageCam = this.store.pageCam;
      let promises = [];

      for (let cam of this.store.currentDivision.cameras) {
        promises.push(this.getDataSequence([this.currentTime - 10, this.currentTime + 100], cam));
      }

      await Promise.allSettled(promises).finally(() => {
        this.allCams = this.allCamsTmp;
        this.allCamsTmp = [];
        this.loading = false;
      })

      this.sortAllCams();
    },

    async reloadTrack(camIndex) {
      if (this.allMode) {
        let range = [this.allCams[camIndex].range[1], this.allCams[camIndex].range[1] + 110];
        let quality = this.allCams[camIndex].quality;

        console.dir(`reload ${this.allCams[camIndex].name}`);
        const { data: result } = await ApiSys.getDateSequence({
          date: moment(this.dateFromCalendar).format('YYYY_MM_D'),
          uuid: this.division,
          cam: this.allCams[camIndex].name,
          range,
          quality,
          address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
        });

        this.$set(this.allCams, camIndex, {
          display: true,
          src: window.location.protocol == "http:" ? `//${this.store.currentDivision.address}/${result.descriptor}` : `//${this.store.currentDivision.url}/${result.descriptor}`,
          date: null,
          name: this.allCams[camIndex].name,
          range,
          quality,
        });

        this.$refs.player[camIndex].initialize();
      } else {
        let interval = [this.startRangeForOneCamMode, this.startRangeForOneCamMode + LOAD_TIME];
        this.startRangeForOneCamMode = this.startRangeForOneCamMode + LOAD_TIME;

        this.getDataSequence(interval, this.camId);
      }
    },

    changeCalendar({ month, year }) {
      if (this.allMode) {
        return
      }

      this.getArchiveCalendar(this.camId, { month, year })
    },

    async getArchiveCalendar(cam, { year, month }) {
      try {
        this.$refs.calendar.setLoadState(true);

        const { data: result } = await ApiSys.getArchiveForMonth({
          date: `${year}_0${month}_01`,
          cam,
          address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
        });
        if (result) {
          result.forEach(d => {
            if (d.isAvailable) {
              this.$refs.calendar.enableDay(new Date(d.date));
            }
          })
        }
      } catch (e) {
        //
      }

      this.$refs.calendar.setLoadState(false);
    },

    async getDataSequence(range = MAX_INTERVAL, cam, quality = 'f') {
      this.videoShow = false;
      this.cancelSequenceLoad();
      // this.sequenceLoader = true;
      try {
        const { data: result } = await ApiSys.getDateSequence({
          date: moment(this.dateFromCalendar).format('YYYY_MM_D'),
          uuid: this.division,
          cam,
          range,
          quality,
          address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
        });

        if (this.allMode) {
          // this.rangeModel = result.time;
          // this.maxRange = result.time;
          this.allCamsTmp.push({
            display: true,
            date: null, // moment(new Date()),
            src: window.location.protocol == "http:" ? `//${this.store.currentDivision.address}/${result.descriptor}` : `//${this.store.currentDivision.url}/${result.descriptor}`,
            name: cam,
            range,
            quality,
          });
        } else {
          if (!result.time) {
            alert('Нет данных за указанное время');

            if (this.oldCurrentTime) {
              this.currentTime = Number(this.oldCurrentTime) - 1;
              this.updateTimeLineCursor(this.currentTime);
            }
          }

          if (!result || !result.date) {
            this.videoShow = true;

            return;
          }

          this.rangeModel = result.time;
          this.videoSrc = window.location.protocol == "http:" ? `//${this.store.currentDivision.address}/${result.descriptor}` : `//${this.store.currentDivision.url}/${result.descriptor}`;
          this.store.setDateSave(this.dateFromCalendar)
        }

        this.videoShow = true;
      } catch (e) {
        this.loadSequence(this.dateFromCalendar);
        return;
      }
    },

    openDownloadModal() {
      this.modalShow = true
      this.isDownloadModalOpen = true;
    },

    closeDownloadModal() {
      this.modalShow = false
      this.isDownloadModalOpen = false;
    },

    async downLoad(range) {
      this.downloadLoader = true;
      this.videoGuid = this.generateGuid();

      let options = {
        requestUUID: this.videoGuid,
        date: moment(this.dateFromCalendar).format('YYYY_MM_D'),
        uuid: this.division,
        cam: this.camId,
        range,
        quality: 'f',
        address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
      };

      let result = await ApiSys.getDownloadLink(options);
      if (result.error || result.errors) {
        this.canceldownLoad();
        this.$toast.error("Не удалось загрузить видео");
      }
      if (!result.error && !result.errors) {
        if (this.downloadOldFormat) {
          const fullPath = result.data.files || result.data.path;
          const a = fullPath.split('/').pop().split("_");
          const filename = `${this.store.currentDivision.name}_${this.camId}_${a[1]}_${a[2]}_${a[3]}__${a[4]}_${a[5]}_${a[6]}-${a[7]}_${a[8]}`;
          this.progress = [{
            filename,
            percent: false,
            ready: true,
            fullPath
          }];
        } else {
          const links = result.data.files

          this.progress = this.progress.map(file => {
            const link = links.find(link => link.endsWith(file.filename));
            if (link) {
              return {
                filename: file.filename,
                percent: 100,
                ready: true,
                fullPath: link
              }
            }

            return file;
          })
        }

        this.downloadLink = result.data.path
        this.downloadLoader = false;
      }
    },

    async canceldownLoad() {
      this.progressShow = false
      this.progressBtnShow = false
      clearInterval(this.intervalProgress)

      await ApiSys.cancelDownloadLink({
        'requestUUID': this.videoGuid,
        address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
      }).then(() => {
        this.progress = []
        this.progressShow = false
        this.progressBtnShow = false

        this.videoGuid = null;
        this.downloadLink = null;
        this.downloadLoader = false;
      })
    },

    backToArh() {
      this.videoGuid = null;
      this.downloadLink = null;
      this.downloadLoader = false;

      this.progress = []
      this.progressShow = false
      this.progressBtnShow = false
      this.downloadOldFormat = false
    },

    generateGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : (r & 0x3 | 0x8);

        return v.toString(16);
      });
    },

    onCamMove(type) {
      const camList = this.store.currentDivision.camList;
      let cam = this.$route.params.cam;
      const camListLength = camList.length;
      const currentIndex = camList.findIndex(c => c === cam);

      let index = 0;

      if (type === "prev") {
        index = currentIndex > 0 ? currentIndex - 1 : camListLength - 1;
      } else {
        index = currentIndex < camListLength - 1 ? currentIndex + 1 : 0;
      }

      cam = camList[index];
      this.routeToCam(cam);
    },

    routeToCam(cam) {
      this.store.setPageTitle(
        "Камера: " + this.store.currentDivision.name + " / " + cam
      );

      this.$router.push({
        name: "cam-archive",
        params: { cam, division: this.division }
      });
    },

    onCaptureFrame() {
      const video = this.$refs.player.$refs["video"]
      const canvas = document.createElement("canvas");

      const width = canvas.width = video.offsetWidth
      const height = canvas.height = video.offsetHeight

      const ctx_draw = canvas.getContext('2d');
      ctx_draw.drawImage(video, 0, 0, width, height);

      this.frameUrl = canvas.toDataURL()

      this.fileFrameName = true
    },

    onSaveCaptureFrame(fileName) {
      const frameUrl = this.frameUrl
      const link = document.createElement("a");
      link.setAttribute("href", frameUrl);
      link.setAttribute("download", `${fileName}.jpg`);
      link.click();
      this.fileFrameName = false
    },

    onCloseSaveCaptureFrame() {
      this.fileFrameName = false
    },

    async checkProgress() {
      const options = {
        address: window.location.protocol == "http:" ? this.store.currentDivision.address : this.store.currentDivision.url
      }

      const params = {
        RequestUUID: this.videoGuid
      }

      try {
        const progress = await ApiSys.getProgress(options, params);
        this.progress = progress;

        if (this.progressBtnShow == false) {
          this.progressShow = true
          this.progressBtnShow = true
        }
      } catch (error) {
        this.downloadOldFormat = true
        this.progressShow = true
        this.progressBtnShow = true
        clearInterval(this.intervalProgress)
      }
    },

    onHidden() {
      this.progressShow = false
    },

    onShow() {
      this.progressShow = true
    }
  }
};
</script>

<style lang="stylus" scoped>

  .options {
    display flex;
    flex-wrap wrap
  }

    .options__item {
      margin 5px
    }

.cam-archive {
  display: flex;
  flex-direction: column;
  height: calc(100% - 35px);
  padding: 10px
  overflow-y: auto
  overflow-x: hidden

  &__player {

    .vjs-volume-panel {
      margin-right: auto;
    }
    .vjs-progress-control,
    .vjs-time-control,
    .vjs-picture-in-picture-control {
      display: none;
    }

    .vjs-fullscreen-control {
      order: 3;
    }
  }
}

.progress-list {
  position: absolute;
  width: 550px;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}

.cam-view-wrap {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center
  background: #121518

  .all-cams-view-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .cam-list__item {
    position relative
    display: inline-block;
    overflow: hidden;
  }

  .cam-list__item-3 {
    width 33.3333%
  }

  .cam-list__item-4 {
    width: 25%;
  }

  .cam-list__item-5 {
    width: 20%;
  }

  .cam-list__item-6 {
    width: 16.666666%;
  }

  .cam-list__item-video {
    width: 100%;
    height: 100%;
    padding-bottom: 40%;
  }

  .cam-view-container {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    overflow: hidden;
  }

  .cam-time {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
    position: absolute
    right: 5px
    top: 0
    padding: 5px 15px 10px 15px
    font-size: 1em
    color: #fff
    background: rgba(0, 0, 0, 0.62)
  }

  .cam-name {
    background: #272c33;
    position: absolute;
    height: 20px;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 15px 10px 15px 10px;
    font-size: 14px;
    line-height: 0;
    opacity: 0.8;
  }

  .cam-view-cam {
    width: 100%;
    height: 100%;
  }

  .cam-view__btn {
    position: absolute;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    padding: 0;

    &.cam-view__btn_prev {
      left: 0;
    }

    &.cam-view__btn_next {
      right: 0;
    }

    &.cam-view__btn_frame {
      right: 0px;
      top: 30px;
    }

    .cam-view__icon {
      color: #383e47;
      opacity: 0.35;
      font-size: 50px;
      display: block;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.btn_show {
  margin-right: 5px;
}
</style>
