<template>
    <div class="text-container" @mouseover="startScrolling" @mouseleave="stopScrolling">
      <span ref="text" class="scroll-text">
        <slot></slot>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ScrollingText',
    methods: {
      startScrolling() {
        const textElement = this.$refs.text;
        const containerWidth = textElement.parentNode.offsetWidth;
        const textWidth = textElement.offsetWidth;
  
        if (textWidth > containerWidth) {
          textElement.style.transitionDuration = `${textWidth / 300}s`;
          textElement.classList.add('scrolling');
          textElement.style.transform = `translateX(-${textWidth - containerWidth}px)`;
        }
      },
      stopScrolling() {
        const textElement = this.$refs.text;
        textElement.classList.remove('scrolling');
        textElement.style.transform = 'translateX(0)';
      }
    }
  }
  </script>
  
  <style scoped>
  .text-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .scroll-text {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }
  
  .scroll-text.scrolling {
    transition: transform .03s linear;
  }
  </style>
  