
<template>
  <CPagination :activePage="currentPage" :pages="totalPages" align="center" @update:activePage="updatePage">
    <CPaginationItem @click="goToPage(currentPage - 1)">&laquo;</CPaginationItem>
    <CPaginationItem v-for="page in visiblePages" :key="page" :active="page === currentPage" @click="goToPage(page)">
      {{ page }}
    </CPaginationItem>
    <CPaginationItem @click="goToPage(currentPage + 1)">&raquo;</CPaginationItem>
  </CPagination>
</template>
<script lang="js">

import { CPagination, CPaginationItem } from '@coreui/bootstrap-vue';
export default {
  components: {
    CPagination,
    CPaginationItem
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    maxVisiblePages: {
      type: Number,
      default: 24,
    }
  },
  emits: ['update:currentPage'],
  computed: {
    visiblePages() {
      if (this.totalPages <= this.maxVisiblePages) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
      }

      const half = Math.floor(this.maxVisiblePages / 2);
      let start = Math.max(this.currentPage - half, 1);
      let end = start + this.maxVisiblePages - 1;

      if (end > this.totalPages) {
        end = this.totalPages;
        start = end - this.maxVisiblePages + 1;
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('update:currentPage', page);
      }
    },
    goNext() {
      if (this.currentPage + 1 <= this.totalPages) this.goToPage(this.currentPage + 1);
    },
    goPrev() {
      if (this.currentPage - 1 >= 1) this.goToPage(this.currentPage - 1);
    },
    updatePage(page) {
      this.goToPage(page);
    },
  },
};
</script>

<style lang="stylus">
.pagination-component {
  width: 100%
  background: c-body-bg

  .pagination {
    display: flex
    justify-content: center
  }
  .pagination>* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
  .pagination > li > a,
  .pagination > li > span {
    height: 36px;
    width: 36px;
    padding: 0;
    text-align: center
    border-radius: 50% !important
    margin: 0 5px;
    line-height: 34px;
    color: c-accent-text
    border-color: c-accent-bg

    &:hover {
      background: c-accent-bg
      color: c-title
    }
  }


  .page-item.disabled .page-link {
    color: #505864;
    pointer-events: none;
    cursor: auto;
    background-color: #272c33;
    border-color: #383e47;
    opacity: 0.5

  }

}

</style>
