<template>
  <aside class="sidebar">

    <div class="header">
      <div class="b-logo">
        <a v-if="!isHideSidebar" href="#" class="logo_img"></a>
        <a v-else href="#" class="logo_img_small"></a>
      </div>
    </div>

    <div v-if="store.currentUser" class="user">
      <span class="mdi mdi-account-circle"></span>
      <span class="username">{{ store.currentUser.user }}</span>
    </div>

    <div v-if="store.currentUser" class="sidebar-menu">

      <ul v-if="isAdmin" class="border-bottom border-secondary pb-3 px-0">
        <li class="nav-link">
          <router-link :to="{ path: '/admin/editor' }" class="sidebar-link" :class="{
            'selected': route === 'editor'
          }">
            <span class="mdi mdi-file-tree"></span>
            <span class="text">Редактор</span>
          </router-link>
        </li>
        <li v-if="routeAdmin" class="nav-link">
          <a href="#" @click.prevent="selectFirstDivision">
            <span class="mdi mdi-camcorder"></span>
            <span class="text">К просмотру камер</span>
          </a>
        </li>
      </ul>

      <ul v-if="!routeAdmin" class="side-division-list-small px-0">
        <li class="nav-link">
          <a @click.prevent="() => { store.setHideSidebar(!isHideSidebar) }">
            <span class="mdi mdi-server"></span>
            <span class="text"></span>
          </a>
        </li>
      </ul>

      <ul class="mb-0 px-0" v-if="!routeAdmin">
        <li class="nav-link">
          <router-link :to="{ path: '/favorite' }">
            <span class="mdi mdi-star"></span>
            <span class="text">Избранное</span>
          </router-link>
        </li>
      </ul>
      <SidebarTree v-if="!routeAdmin" :treeData="tree" :hide="isHideSidebar" />
    </div>
    <div v-if="!isHideSidebar" class="sidebar__close" @click="closeDialog">
      <span class="mdi mdi-close"></span>
    </div>
  </aside>
</template>

<script>
import { useDefaultStore } from '@/store';
import SidebarTree from './SidebarTree.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarTree
  },

  props: {
    currentUser: {
      default: () => {
        return null;
      },
      type: Object
    }
  },

  data: () => ({
    route: '',
    divisionGuid: null,
    menu: false,
    activeIndex: null,
    addCamerasShow: false,
    showMobileMenu: false,
    currentDivisions: [],
    treeData: []
  }),
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  mounted() {
  },
  computed: {
    routeAdmin() {
      return this.$route.meta.admin;
    },
    isAdmin() {
      return this.store.isAdmin;
    },
    isHideSidebar() {
      return this.store.isHideSidebar;
    },
    tree() {
      if (this.store.currentUser) {
        const data = this.store.currentUser.groups;
        const nodes = {};
        data.forEach(item => {
          nodes[item.groupId] = { ...item, children: [], type: "group" };
        });

        let root = {};

        data.forEach(item => {
          if (item.parent === null) {
            root = nodes[item.groupId];
          } else {
            nodes[item.parent].children.push(nodes[item.groupId]);
          }
        });
        return [root];
      }
      return [];
    },
  },

  async created() {
    this.route = this.$route.name;
    this.divisionGuid = this.$route.params.division;
  },

  methods: {
    selectFirstDivision() {
      this.$router.push({
        name: 'cam-listNew',
      });
    },

    showDivisionModal() {
      this.showMobileMenu = true;
    },
    showAddCamsModal() {
      this.addCamerasShow = true;
    },
    closeDialog() {
      this.store.setHideSidebar(true);
    }
  }
};
</script>

<style lang="stylus" scoped>
.sidebar__close {
  visibility hidden
  position: absolute;
  display flex
  justify-content center
  align-items center
  width: 40px;
  height: 40px;
  background: inherit;
  right: -40px;
  top: calc(50% - 20px);
  border-bottom-right-radius 10px
  border-top-right-radius 10px
  cursor pointer
  .mdi-close {
    font-size 28px
  }
}

@media (max-width: 699px) {
  .sidebar__close {
    visibility visible
  }
}

.sidebar {
  background-color: c-sidebar-bg
  bottom: 0
  position: fixed
  top: 0
  transition: all .2s ease
  width: 300px

  .header {
    height 50px
    border-bottom 1px solid c-border

    .b-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #389af0;

      .logo_img {
        display: block;
        width: 100%;
        height: 40px;
        background: url("../../assets/img/pervaya.png") no-repeat center;
        background-size: contain;
      }

      .logo_img_small {
        display: block;
        width: 40px;
        height: 40px;
        background: url("../../assets/img/logo.png") no-repeat center;
        background-size: contain;
      }

      .mdi {
        font-size: 32px
      }

      .text {
        margin-left: 10px
        font-size: 20px
      }
    }

  }


  .user {
    background: c-body-bg
    height: 50px
    line-height: 50px
    color: c-accent-text
    padding: 0 10px
    margin-bottom: 10px
    font-size: 16px

    .mdi {
      font-size: 24px
      margin-right: 5px
      vertical-align: middle;
    }
  }
}

.nav-link>a {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  padding: 5px 10px;
  align-items: center;
  .text {
    font-size: 18px;
  margin-left: 10px;
  overflow: hidden;
  }
  .mdi {
    font-size: 30px
  }
}

.sidebar-menu {
  padding-top: 0
  height: calc(100% - 100px)
  overflow-y: auto
  overflow-x: hidden

  .side-division-list-small {
    display: none
  }
}

.sidebar-collapse {
  .sidebar {
    width: 50px

    .sidebar-menu {
      a {
        .text {
          visibility hidden
        }
      }

      .side-division-list {
        display: none
      }

      .side-division-list-small {
        display: block
      }

    }


    .b-logo {
      .text {
        display none
      }
    }

    .username {
      display none
    }
  }
}
.sidebar-link:hover {
    background-color: #464646;
}

.selected {
    background-color: #389af0;
}

</style>
