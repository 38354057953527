<template>
  <header class="main-header">
    <div class="toggle-sidebar mdi mdi-menu" @click="toggleSidebar">
    </div>
    <div class="top-menu">
      <a class="btn" @click="logout">
        <i class="mdi mdi-logout"></i>
      </a>
    </div>
  </header>
</template>

<script lang="js">
import User from '../services/user';
import { useDefaultStore } from '@/store';
export default {
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  methods: {
    toggleSidebar: function () {
      this.store.toggleSidebar();
    },

    logout: function () {
      User.logout();
    }
  }
};
</script>

<style lang="stylus">
.main-header {
  background-color: c-header-bg
  color: c-header-text
  height: 50px
  margin-bottom: 0
  padding: 0
  position: fixed
  width: calc(100% - 300px)
  z-index: 800
  display: flex;
  justify-content: space-between;
  transition: all .2s ease

  .toggle-sidebar {
    float: left
    margin-right: 24px
    font-size: 24px
    cursor: pointer
    width: 50px
    height: 50px
    text-align: center
    line-height: 50px

    &:hover {
      color: $c-accent
    }
  }

  .top-menu {

    .btn {
      color: $c-header-text
      font-size 24px
      height 50px
      line-height 50px
      width 50px
      padding 0

      &:focus {
        box-shadow: none
      }

      &:hover {
        color: #b00000
      }
    }
  }
}

.sidebar-collapse {
  .main-header {
    width: calc(100% - 50px)
  }
}

@media (max-width: 1024px) {

  .main-header {
    width: calc(100% - 50px)

    .toggle-sidebar {
      margin-left: 250px
      transition: all 250ms
    }
  }

  .sidebar-collapse {
    .main-header {
      width: calc(100% - 50px)

      .toggle-sidebar {
        margin-left: 0
      }
    }
  }
}

</style>
