<template>
  <vue-slider class="cam-timeline-cursor" v-model="cursor" :min="min" :max="max" :interval="1"
              :tooltip-formatter="formatTime" lazy ref="slider" @mousemove="setTooltip"
              @mouseleave="hideTooltip">
    <template #dot>
      <div class="vue-slider-cursor"></div>
    </template>

    <template #default>
      <div v-show="isTooltipVisible" class="vue-slider-process-tooltip" :style="{ left: `${tooltipPosition}%` }">
        <div class="vue-slider-dot-tooltip vue-slider-dot-tooltip-top vue-slider-dot-tooltip-visible">
          <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top">
            <span class="vue-slider-dot-tooltip-text">{{ formatTime(tooltipValue) }}</span>
          </div>
        </div>
      </div>
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component';
const formatTime = (time) => {
  const SECS_IN_MIN = 60;
  const SECS_IN_HOUR = 60 * SECS_IN_MIN;
  const hours = Math.floor(time / SECS_IN_HOUR);
  const mins = Math.floor((time - hours * SECS_IN_HOUR) / SECS_IN_MIN);
  const formattedMins = String(mins).padStart(2, '0');
  return `${hours}:${formattedMins}`;
};
export default {
  components: {
    VueSlider
  },
  props: {
    value: Number,
    min: Number,
    max: Number
  },
  emits: ['update:value'],
  data() {
    return {
      isTooltipVisible: false,
      tooltipValue: 0,
      tooltipPosition: 0
    };
  },
  computed: {
    cursor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    }
  },
  methods: {
    formatTime,
    setTooltip(event) {
      const { slider } = this.$refs;
      slider.setScale();
      const position = slider.getPosByEvent(event);
      this.tooltipPosition = position;
      this.tooltipValue = slider.control.getValueByPos(position);
      this.isTooltipVisible = true;
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    }
  }
};
</script>

<style lang="stylus">
.cam-timeline-cursor {
  padding: 0 !important;
  .vue-slider-process {
    background-color: rgba(#fff, 0.5);
  }
  .vue-slider-dot {
  }
}
</style>