<template>
  <div id="wrapper" :class="{ 'sidebar-collapse': isHideSidebar }">
    <div class="page-container">
      <main-header></main-header>
      <div class="main-content">
        <page-header></page-header>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <main-footer></main-footer>
    </div>
    <sidebar @close="closeSidebar" />
    <!-- <div v-if="!isHideSidebar && smallScreen" class="sidebar-overlay" @click="closeSidebar"></div> -->

    <main-loader v-if="isLoader"></main-loader>
  </div>
</template>

<script lang="js">
import Sidebar from '../components/Sidebar/Sidebar.vue';
import MainHeader from '../components/Header.vue';
import PageHeader from '../components/PageHeader.vue';
import MainFooter from '../components/Footer.vue';
import MainLoader from '../components/Loader.vue';
import { mapDivisionsNameAddress } from '@/services/divisions';
import { ApiSys } from '@/services/api';
import notify from '@/services/notify';
import { useDefaultStore } from '@/store';

export default {
  name: 'Admin',
  components: {
    Sidebar,
    MainHeader,
    MainFooter,
    MainLoader,
    PageHeader
  },

  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    isHideSidebar() {
      return this.store.isHideSidebar;
    },

    smallScreen() {
      return this.store.smallScreen;
    },

    isLoader() {
      return this.store.loader;
    }
  },

  async created() {
    await this.getDivisions();
    await this.getUser();
  },

  methods: {
    closeSidebar() {
      this.store.closeSidebar();
    },

    async getDivisions() {
      let result = await ApiSys.getDivisions();
      if (result.error) {
        this.$toast.error(result.error.message);
        this.allDivisions = [];
      } else {
        this.allDivisions = result.data;
      }
    },

    async getUser() {
      // this.currentUser['availableDivisions'] = await mapDivisionsNameAddress(this.allDivisions, this.currentUser);
    }

  }

};
</script>

<style lang="stylus">
  .sidebar-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
</style>
