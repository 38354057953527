<template>
  <div class="cam-timeline">
    <vue-slider :step-style="{
      width: '2px'
    }" v-model="dots" :min="min" :max="max" :enable-cross="false" :clickable="false" :silent="true" :lazy="true"
                :interval="minRange" :process="true" :marks="marksFormatter" :tooltip-formatter="formatTime" ref="region"
                @drag-start="cancelIntervalUpdate" @wheel="scaleInterval">
      <template #process="{ style }">
        <cam-timeline-cursor class="vue-slider-timeline-cursor" :style="style" :value="cursorModel"
                             @update:value="updateCursor" :min="dots[0]" :max="dots[1]" />
      </template>
    </vue-slider>
    <div class="row">
      <div class="col-6 text-left">{{ formatTime(min) }}</div>
      <!--<div class="col-4 text-center text-purple">
        <b>{{ formatTime(cursorModel, true) }}</b>
      </div>-->
      <div class="col-6 col-6 d-flex justify-content-end">{{ formatTime(max) }}</div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import CamTimelineCursor from "./CamTimelineCursor.vue";
import { keepInInterval, formatTime } from "@/helpers";
import * as _ from 'lodash-es';

const STEPS = [1, 10, 30, 60, 120, 300, 600, 1800, 3600, 7200, 14400];
const [DEFAULT_STEP] = STEPS;
const WHEEL_SPEED = -0.01;
const MIN_STEP_WIDTH = 30;
const MAX_STEP_WIDTH = 100;
const MIN_RANGE = 60;
const HALF_RANGE = Math.round(MIN_RANGE / 2);
const getFitStep = width => {
  for (const step of STEPS) {
    const stepWidth = Math.floor(width * step);
    // stepWidth will only get bigger, so there's no point to look further
    if (stepWidth > MAX_STEP_WIDTH) return step;
    if (MIN_STEP_WIDTH <= stepWidth) {
      return step;
    }
  }
  return DEFAULT_STEP;
};
export default {
  name: "CamTimeline",
  components: {
    CamTimelineCursor,
    VueSlider
  },
  props: {
    range: Array,
    cursor: Number,
    maxInterval: Array
  },
  emits: ["update:cursor", "update:range"],
  data() {
    return {
      regionWidth: null,
      intervalValue: [...this.maxInterval]
    };
  },
  computed: {
    interval: {
      get() {
        return this.intervalValue;
      },
      set(interval) {
        const [min, max] = this.maxInterval;
        const [left, right] = interval;
        const [rightBound, leftBound] = this.intervalValue;
        this.intervalValue = [
          keepInInterval(min, leftBound - HALF_RANGE, left),
          keepInInterval(rightBound + HALF_RANGE, max, right)
        ];
      }
    },
    dots: {
      get() {
        const [min, max] = this.range;
        const [start, end] = this.interval;
        return [Math.max(min, start), Math.min(end, max)];
      },
      set([left, right]) {
        const [min, max] = this.interval;
        const rightBound = left + this.halfRange;
        const leftBound = right - this.halfRange;
        const start = keepInInterval(min, leftBound, left);
        const end = keepInInterval(rightBound, max, right);

        const range = [start, end];
        this.$emit("update:range", range);
      }
    },
    cursorModel: {
      get() {
        return this.cursor;
      },
      set(cursor) {
        localStorage.setItem("cursorPosition", cursor);
        this.$emit("update:cursor", cursor);
      }
    },
    duration() {
      const [start, end] = this.interval;

      return end - start;
    },
    // visible marks
    step() {
      if (!this.regionWidth) return DEFAULT_STEP;
      const width = this.regionWidth / this.duration;

      return getFitStep(width);
    },
    min() {
      const [min] = this.interval;

      return min;
    },
    max() {
      const excess = this.duration % this.step;
      const over = excess > 0 ? this.step - excess : 0;
      const [, max] = this.interval;

      return max + over;
    },
    minRange() {
      return MIN_RANGE;
    },
    halfRange() {
      return Math.round(this.minRange / 2);
    },
    scaleSpeed() {
      return this.step * WHEEL_SPEED;
    },
  },
  mounted() {
    this.computeRegionWidth();
    this.onResize = _.throttle(this.computeRegionWidth, 100, {
      leading: false,
      trailing: true
    });
    window.addEventListener("resize", this.onResize);

    this.cursorModel = localStorage.cursorPosition
      ? parseInt(localStorage.cursorPosition)
      : 0;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    formatTime,
    updateCursor(cursor) {
      this.cursorModel = cursor;
    },
    setInterval(interval) {
      this.interval = interval;
    },
    marksFormatter(value) {
      const markModule = value % this.step;
      if (markModule >= MIN_RANGE) {
        return false;
      }
      return {
        label: formatTime(value)
      };
    },
    getProcess(dots) {
      const [left, value, right] = dots;
      return [
        [left, value],
        [value, right]
      ];
    },
    computeRegionWidth() {
      const { region } = this.$refs;
      if (!region) return;
      const computedWitdh = getComputedStyle(region.$el).width;
      this.regionWidth = parseInt(computedWitdh, 10);
    },
    cancelIntervalUpdate() {
      this.$emit("cancel-load");
    },
    zoomIn(event) {
      const { target, offsetX, deltaY } = event;
      const { offsetLeft } = target;
      const position = offsetLeft + offsetX;
      const [left, right] = this.interval;
      const leftDelta = position / this.regionWidth;
      const rightDelta = 1 - leftDelta;
      const change = deltaY * this.scaleSpeed;
      const leftChange = Math.round(leftDelta * change);
      const rightChange = Math.round(rightDelta * change);
      this.interval = [left + leftChange, right - rightChange];
    },
    zoomOut(event) {
      const { deltaY } = event;
      const [start, end] = this.range;
      const [left, right] = this.interval;
      const leftOffset = start - left;
      const rightOffset = right - end;
      const totalOffset = leftOffset + rightOffset || 1;
      const leftDelta = leftOffset / totalOffset;
      const rightDelta = 1 - leftDelta;
      const change = deltaY * this.scaleSpeed;
      const leftChange = Math.round(leftDelta * change);
      const rightChange = Math.round(rightDelta * change);
      this.interval = [left + leftChange, right - rightChange];
    },
    scaleInterval(event) {
      if (event.deltaY > 0) {
        this.zoomOut(event);
      } else {
        this.zoomIn(event);
      }
    }
  }
};
</script>

<style lang="stylus">
.cam-timeline {
  .vue-slider {
    height: 40px !important;
    padding-bottom: 40px !important;
  }
  /* rail style */
  .vue-slider-rail {
    background-color: rgba(#e1e1e1, 0.2);
    transition: background-color 0.3s;
  }
  .vue-slider:hover .vue-slider-rail {
  }
  .vue-slider-region {
    position: absolute
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .vue-slider-process {
    &-active {
      background-color: rgba(#fff, 0.5);
    }
  }
  /* mark style */
  .vue-slider-mark {
    pointer-events: none;
  }
  .vue-slider-mark-step {
    width: 100%;
    height: 100%;
    background-color: #444;
  }
  .vue-slider-mark-label {
    font-size: 12px;
    white-space: nowrap;
  }
  /* dot style */
  .vue-slider-timeline-cursor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .vue-slider-cursor {
    cursor-color = #ffffff;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 4px;
    background-color: cursor-color;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position absolute;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 10px;
      transform: translateX(-50%);
    }
    &::before {
      bottom: calc(100% - 5px);
      border-bottom-width: 0;
      border-top-color: cursor-color;
    }
    &::after {
      top: calc(100% - 5px);
      border-top-width: 0;
      border-bottom-color: cursor-color;
    }
  }
  .vue-slider-dot {
    height: 100% !important;
    width: 0px !important;
  }
  .vue-slider-dot-handle {
    &-disabled {
      cursor: not-allowed;
      border-color: #ddd !important;
    }
  }
  .vue-slider-dot-tooltip {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    &-inner {
      font-size: 14px;
      white-space: nowrap;
      padding: 6px 8px;
      color: #fff;
      border-radius: 5px;
      border-color: rgba(#000, 0.75);
      background-color: rgba(#000, 0.75);
      box-shadow: 0 2px 8px rgba(#000, 0.15);
      transform: scale(0.9);
      transition: transform 0.3s;
    }
    &-inner-top {
      transform-origin: 50% 100%;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        height: 0;
        width: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px;
        border-top-color: inherit;
      }
    }
    &-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .vue-slider-process-tooltip {
    position: absolute;
    top: 0;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      width: 2px;
      height: 40px
      background-color #fff
      transform: translateX(-50%);
    }
  }
  .vue-slider-dot:hover .vue-slider-dot-tooltip,
  .vue-slider-dot-tooltip-show {
    opacity: 1;
    visibility: visible;
  }
  .vue-slider-dot:hover .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner,
  .vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner {
    transform: scale(1);
  }
}
</style>
