
import { useDefaultStore } from "@/store";
import { ApiSys } from "./api";

export function mapDivisionsNameAddress(allDivisions, user) {
  let byGuid = [];
  let divisions = user.availableDivisions;

  if (divisions.byIndex && divisions.byIndex) {
    return divisions;
  }

  divisions.forEach(d => {
    let findDevision = allDivisions.find(el => el['uuid'] === d['divisionUuid']);
    if (findDevision) {
      d.name = findDevision.name;
      d.address = findDevision.address;
    } else {
      d.name = 'Объект не найден';
      d.address = null;
      d.notFound = true;
    }
    byGuid[d.divisionUuid] = d;
  });

  return {
    byIndex: divisions,
    byGuid: byGuid
  };
}


export const loadDivisions = async () => {
  const store = useDefaultStore();
  if (!localStorage.token || localStorage.token == "") return;
  let result = await ApiSys.getUserDivisions();
  if (result.error) {
    console.log(result.error);
  } else {
    store.setAllDivisions(result);
  }
}