import { reactive, shallowRef } from 'vue';

const modalState = reactive({
    isVisible: false,
    standaloneComponent: false,
    title: '',
    componentToRender: shallowRef(null),
    componentProps: shallowRef({}),
    eventHandlers: {},
    size: undefined,
});

function showModal(component, props = {}, events = {}, title = '', size = undefined, standaloneComponent = false) {
    modalState.componentToRender = component;
    modalState.componentProps = props;
    modalState.eventHandlers = events;
    modalState.isVisible = true;
    modalState.title = title;
    modalState.size = size;
    modalState.standaloneComponent = standaloneComponent;
}

function hideModal() {
    modalState.isVisible = false;
    modalState.componentToRender = null;
    modalState.componentProps = {};
    modalState.eventHandlers = {};
    modalState.title = '';
}

export function useModal() {
    return {
        modalState,
        showModal,
        hideModal,
    };
}