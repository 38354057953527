import 'reset-css/reset.css'
import '@mdi/font/css/materialdesignicons.min.css'
import '@/assets/css/opensans.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/master_style.css'
import '@/assets/css/bs-custom.styl'
import '@/assets/css/style.styl'
import 'video.js/dist/video-js.css'

import { createApp } from 'vue'
import App from '@/App.vue'
import { router } from '@/router'
import '@/plugins/videojs-zoom'
import { createPinia } from 'pinia'
import PortalVue from 'portal-vue'
import { loadDivisions } from '@/services/divisions'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import disableAutocomplete from './plugins/vue/disable-autofill'
import VueYandexMetrika from '@/plugins/vue/metrika'
// import rollbar from './plugins/vue/rollbar';
/*
 interface IDeviceDetector {
 ios: Boolean
 iphone: Boolean
 iphoneX: Boolean
 iPhoneXR: Boolean
 iPhoneXSMax: Boolean
 ipod: Boolean
 ipad: Boolean
 android: Boolean
 androidPhone: Boolean
 windows: Boolean
 mobile: Boolean
 dingding: Boolean //钉钉
 wechat: Boolean, // 微信
 wechatMiniApp: Boolean //微信小程序
 }
*/

// console.log(this.$device.ios) // true

/**
 * Отложенный запуск приложения
 * Переход на async
 */
setTimeout(async () => {
  try {
    await initApp()
    await launchApp()
  } catch (e) {
    await launchApp(e)
  }
})
async function launchApp(error) {
  const app = createApp(App)
  // app.use(rollbar)
  app.use(createPinia())
  if (localStorage && localStorage.token != '') await loadDivisions()
  app.use(router)
  app.use(disableAutocomplete)
  app.use(PortalVue)
  app.use(ToastPlugin, {
    position: 'top-right'
  })
  app.use(VueYandexMetrika, {
    id: '93206283',
    router: router,
    env: 'production',
      // other options
  })
  // app.config.productionTip = true;
  // app.config.devtools = true;
  app.config.performance = true
  app.mount('#app')
}

/**
 * Инициализация приложения
 */
async function initApp() {}
