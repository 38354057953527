<template>
    <component v-if="modalState.standaloneComponent" :is="modalState.componentToRender" v-bind="modalState.componentProps" v-on="eventListeners"/>
    <CModal v-else scrollable :size="modalState.size" :visible="modalState.isVisible" @close="closeModal">
        <CModalHeader>
            <CModalTitle>{{ modalState.title }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <component :is="modalState.componentToRender" v-bind="modalState.componentProps" v-on="eventListeners">
            </component>
        </CModalBody>
    </CModal>
</template>
  
<script>
import { inject, computed } from 'vue';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/bootstrap-vue';

export default {
    name: 'Modal',
    components: {
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
    },
    setup() {
        const { modalState, hideModal } = inject('modal');

        function closeModal() {
            hideModal();
        }

        // Create a computed property that generates the event listeners dynamically
        const eventListeners = computed(() => {
            const handlers = {};
            for (const [event, handler] of Object.entries(modalState.eventHandlers)) {
                handlers[event] = handler;
            }
            return handlers;
        });

        return {
            modalState,
            closeModal,
            eventListeners,
        };
    },
};
</script>
  
<style scoped></style>
  