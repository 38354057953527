import videojs from 'video.js';

const Button = videojs.getComponent('button');

const NO_ZOOM = 1;
const ZOOM_LIST = [NO_ZOOM, 2, 4];

const keepInInterval = (min, max) => (value) => Math.min(Math.max(value, min), max);

class ZoomButton extends Button {
  /**
   * Create zoom button.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
  constructor(player, options) {
    super(player, options);
    this.zoom = NO_ZOOM;

    this.controlText('Zoom');
  }

  buildCSSClass() {
    return 'vjs-control vjs-button vjs-zoom-button';
  }

  getNextZoom() {
    const currentZoom = ZOOM_LIST.indexOf(this.zoom);
    const nextZoom = (currentZoom + 1) % ZOOM_LIST.length;

    return ZOOM_LIST[nextZoom];
  }

  handleClick() {
    this.zoom = this.getNextZoom();

    this.player()
      .zoomPlugin()
      .zoom({ zoom: this.zoom });
  }
}

const Plugin = videojs.getPlugin('plugin');



class ZoomPlugin extends Plugin {
  /**
   * Create a ZoomPlugin plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */ 
  constructor(p) {
    super(p);

    this.player.ready(() => {
      this.player.getChild('controlBar').addChild('zoomButton');
    });

    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  onDown(event) {
    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');

    let { x, y } = event

    this.start = {
      x: videoElement.offsetLeft - x,
      y: videoElement.offsetTop - y
    };

    this.targetSize = [
      targetElement.offsetWidth,
      targetElement.offsetHeight
    ].map(dimension => dimension * this.zoomCorrection);

    targetElement.classList.add('vjs-zoom-drag');
    document.addEventListener('mousemove', this.onMove);
    document.addEventListener('mouseup', this.onUp);
  }

  onMove(event) {
    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');

    let { x, y } = event;

    const { x: startX, y: startY } = this.start;
    const [width, height] = this.targetSize;

    const left = keepInInterval(-width, width)(x + startX);
    const top = keepInInterval(-height, height)(y + startY);

    videoElement.style.left = `${left}px`;
    videoElement.style.top = `${top}px`;
  }

  onUp() {
    const targetElement = this.player.el();
    targetElement.classList.remove('vjs-zoom-drag');

    document.removeEventListener('mousemove', this.onMove);
    document.removeEventListener('mouseup', this.onUp);
  }

  onTouchStart(event) {
    //event.preventDefault();

    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');

    let { pageX, pageY } = event.touches[0];

    this.start = {
      x: videoElement.offsetLeft - pageX,
      y: videoElement.offsetTop - pageY
    };

    this.targetSize = [
      targetElement.offsetWidth,
      targetElement.offsetHeight
    ].map(dimension => dimension * this.zoomCorrection);

    targetElement.classList.add('vjs-zoom-drag');
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
  }

  onTouchMove(event) {
    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');

    let { clientX: x, clientY: y } = event.touches[0];

    const { x: startX, y: startY } = this.start;
    const [width, height] = this.targetSize;

    const left = keepInInterval(-width, width)(x + startX);
    const top = keepInInterval(-height, height)(y + startY);

    videoElement.style.left = `${left}px`;
    videoElement.style.top = `${top}px`;
  }

  onTouchEnd() {
    const targetElement = this.player.el();
    targetElement.classList.remove('vjs-zoom-drag');

    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  }

  zoom({ zoom }) {
    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');
    const posterElement = targetElement.querySelector('.vjs-poster');

    this.zoomCorrection = (zoom - 1) / 2;

    if (zoom === NO_ZOOM) {
      return this.removeZoom();
    }

    const zoomStyle = `scale(${zoom})`;

    targetElement.style.overflow = 'hidden';
    videoElement.style.transform = zoomStyle;
    posterElement.style.transform = zoomStyle;

    videoElement.addEventListener('mousedown', this.onDown);
  }

  removeZoom() {
    const targetElement = this.player.el();
    const videoElement = targetElement.querySelector('.vjs-tech');
    const posterElement = targetElement.querySelector('.vjs-poster');

    videoElement.style.transform = 'none';
    posterElement.style.transform = 'none';

    videoElement.style.left = 0;
    videoElement.style.top = 0;

    videoElement.removeEventListener('mousedown', this.onDown);
  }
}

ZoomPlugin.VERSION = '1.0.0';

videojs.registerComponent('zoomButton', ZoomButton);
videojs.registerPlugin('zoomPlugin', ZoomPlugin);

export default ZoomPlugin;
