<template>
    <div v-if="item.type == 'group' && item.display" class="tree-item" @click="toggle">
        <div class="tree-icon flex-shrink">📂</div>
        <div class="tree-name flex-grow">
            <input v-if="isRename" @change="onEditGroupName" :value="item.name" ref="input" />
            <span v-if="!isRename">{{ item.name }}</span>
        </div>
        <div class="tree-actions flex-row">
            <div class="tree-action" @click.stop="clickAddGroup">+📂</div>
            <div class="tree-action" @click.stop="clickAddDivision">+🏠</div>
            <div class="tree-action" @click.stop="clickAddUser">+👤</div>
            <div class="tree-action" @click.stop="clickRename">🖍</div>
            <div class="tree-action" @click.stop="clickDelete">🚫</div>
            <div class="tree-action" @click.stop="toggle">{{ isOpen ? '🔼' : '🔽' }}</div>
        </div>
    </div>
</template>
<script>
import { ApiSys } from "@/services/api";
import { useDefaultStore } from "@/store";
import { inject } from 'vue';
import AddUserToGroup from "../AddUserToGroup.vue";
import AddDivisionsToGroup from "../AddDivisionsToGroup.vue";

export default {
    props: ['item', 'toggle', 'isOpen', 'isLoading', 'refetchData', 'parent'],
    emits: ['addDivision', 'addUser'],
    data() {
        return {
            isRename: false
        }
    },
    setup() {
        const store = useDefaultStore();
        const { showModal, hideModal } = inject('modal');
        const updateTree = inject('updateTree');
        return {
            store,
            showModal,
            hideModal,
            updateTree
        }
    },
    methods: {
        async onEditGroupName(e) {
            const name = e.target.value;
            if (name.length == 0) {
                this.$toast.error('Название папки не может быть пустым');
                return;
            }
            const result = await ApiSys.setGroup(this.item.id, {
                name: name,
                parent: this.item.parent
            })

            if (result.error) {
                this.$toast.error(result.error.message);
                return;
            } else {
                this.updateTree();
            }
        },
        clickRename() {
            this.isRename = !this.isRename;
            this.$nextTick(() => {
                try {
                    this.$refs.input.focus()
                } catch (e) {
                    //
                }
            })
        },
        clickAddUser() {
            this.showModal(AddUserToGroup, { group: this.item, hide: this.hideModal }, { "updateTree": () => { this.updateTree() } }, "Добавить пользователя в группу");
        },

        clickAddDivision() {
            this.showModal(AddDivisionsToGroup, { group: this.item, hide: this.hideModal }, { "updateTree": () => { this.updateTree() } }, "Добавить площадку в группу");
        },
        async clickAddGroup() {
            const name = `Новая папка ${Date.now()}`;

            const result = await ApiSys.createGroup({
                name,
                parent: this.item.id
            });

            if (result.error) {
                this.$toast.error(result.error.message);
            } else {
                this.refetchData();
            }
        },

        async clickDelete() {
            const alert = confirm(`Точно удалить группу "${this.item.name}"?`);
            if (alert) {
                const result = await ApiSys.deleteGroup(this.item.id);
                if (result.error) {
                    this.$toast.error(result.error.message);
                } else {
                    this.parent.refetchData();
                    this.$toast.success("Группа успешно удалена");
                }
            }
        }
    }
}
</script>
<style scoped>
.tree-item {
    padding: 7px 12px;
    background: #fff;
    border-radius: 10px;
    color: #000;
    margin-bottom: 5px;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tree-actions {
    display: flex;
    gap: 5px;
}

.tree-action {
    flex: 0 0 auto;
    cursor: pointer;
}
</style>