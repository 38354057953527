<template>
    <div v-if="item.type == 'division' && item.display" class="tree-item" @click="toggle">
        <div class="tree-icon flex-shrink">🏠</div>
        <div class="tree-name flex-grow">{{ item.name }}</div>
        <div class="tree-actions flex-row">
            <div class="tree-action" @click.stop="clickAddUser">+👤</div>
            <div class="tree-action" @click.stop="clickAddCameasToUsers">+📹</div>
            <div class="tree-action" @click.stop="clickRemoveDivision">🚫</div>
            <div class="tree-action" @click.stop="toggle">{{ isOpen ? '🔼' : '🔽' }}</div>
        </div>
    </div>
</template>

  
<script>
import { inject } from 'vue';
import AddUser from "@/components/editor/AddUser.vue";
import AddCamerasToUsers from "@/components/editor/AddCamerasToUsers.vue";
import { ApiSys } from "@/services/api";
export default {
    props: ['item', 'toggle', 'isOpen', 'isLoading', 'refetchData', 'parent', 'loadChildren', 'children'],
    setup() {
        const { showModal, hideModal } = inject('modal');
        const updateTree = inject('updateTree');
        return { showModal, hideModal, updateTree }
    },
    methods: {
        clickAddUser() {
            this.showModal(AddUser, { division: this.item, hide: this.hideModal }, { "updateTree": () => { this.updateTree() } }, "Добавить пользователя в площадку");
        },
        async clickAddCameasToUsers() {
            await this.loadChildren();
            this.showModal(AddCamerasToUsers, { divisionId: this.item.id, hide: this.hideModal, users: this.children }, {}, "Добавить камеры пользователям", "lg")
        },
        async clickRemoveDivision() {
            let answer = confirm(`Точно удалить площадку "${this.item.name}"?`);
            if (answer) {
                let result = await ApiSys.deleteDivision(this.item.id);

                if (result.error) {
                    this.$toast.error(result.error.message);
                } else {
                    this.$toast.success('Площадка успешно удалена!');
                    this.parent.refetchData();
                }
            }

        },
    }

}
</script>
<style scoped>
.tree-item {
    padding: 7px 12px;
    background: #fff;
    border-radius: 10px;
    color: #000;
    margin-bottom: 5px;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tree-actions {
    display: flex;
    gap: 5px;
}

.tree-action {
    flex: 0 0 auto;
    cursor: pointer;
}
</style>